import React, { useMemo } from 'react';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import routes from 'routes';
import ContentCard from 'components/card/ContentCard';
import SidebarMenu from 'components/sidebarMenu/SidebarMenu';
import LogTodaysMedIcon from 'components/icons/LogTodaysMedIcon';
import LogAsNeededMedIcon from 'components/icons/LogAsNeededMedIcon';
import ManageMedicationRemindersIcon from 'components/icons/ManageMedicationRemindersIcon';
import ReviewMyHistoryIcon from 'components/icons/ReviewMyHistoryIcon';
import { Theme, Typography, useMediaQuery } from '@mui/material';
import useShowSidebarNavigation from 'hooks/useShowSidebarNavigation';
import { QueryClient } from '@tanstack/react-query';
import { myScheduleQueries } from 'queries/patient';
import { useLoaderData, useLocation } from 'react-router-dom';
import { GeneralInfoResponse } from 'core/api/mySchedule/mySchedule.models';
import isActiveRoute from 'utils/isActiveRoute';
import { myScheduleTodaysMedicationWalkThrough, WalkThrough } from 'components/patient/walkThrough';

export const loader = (queryClient: QueryClient) => async () => {
	const query = myScheduleQueries.generalInfo();
	return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query));
};

interface MyScheduleLayoutProps {
	children: React.ReactNode;
}

export function MyScheduleLayout({ children }: MyScheduleLayoutProps) {
	const { anyOrderDelivered, currentOrderToday } = useLoaderData() as GeneralInfoResponse;
	const location = useLocation();

	const menuItems = [
		{
			label: 'Today’s Medication',
			icon: <LogTodaysMedIcon fontSize="large" />,
			to: routes.mySchedule.todaysMedication,
			state: { showSidebarNavigation: false },
			activeRoutes: [routes.mySchedule.todaysMedication, routes.mySchedule.todaysMedDetails],
			disabled: !currentOrderToday,
		},
		{
			label: 'Log As-Needed Medication',
			icon: <LogAsNeededMedIcon fontSize="large" />,
			to: routes.mySchedule.logAsNeededMed,
			activeRoutes: [routes.mySchedule.logAsNeededMed],
			disabled: !currentOrderToday,
		},
		{
			label: 'Manage Medication Reminders',
			icon: <ManageMedicationRemindersIcon fontSize="large" />,
			to: routes.mySchedule.manageMedicationReminders,
			activeRoutes: [routes.mySchedule.manageMedicationReminders],
			disabled: !currentOrderToday,
		},
		{
			label: 'Review My History',
			icon: <ReviewMyHistoryIcon fontSize="large" />,
			to: routes.mySchedule.reviewMyHistory.calendar,
			activeRoutes: [routes.mySchedule.reviewMyHistory.calendar, routes.mySchedule.reviewMyHistory.day],
			disabled: !anyOrderDelivered,
		},
	];

	const introText = useMemo(() => {
		if (!currentOrderToday && anyOrderDelivered) {
			return 'Please call us at 1-877-355-7225. We need you to call us before we can send your next order.';
		}

		if (!currentOrderToday && !anyOrderDelivered) {
			return 'We are working on getting your prescriptions transferred to us. You can begin using your medication schedule after we receive them to track and manage what medications you have taken based on feedback from your prescribers, pharmacists, and you. This does not replace consultation from your pharmacist or provider. Call if you have questions for our pharmacists.';
		}

		return 'Your medication schedule is based on feedback from your prescribers, pharmacists and you. Use this section to track if you have taken your medication. This does not replace consultation from your pharmacist or provider. Please call us if you have questions or concerns.';
	}, [anyOrderDelivered, currentOrderToday]);

	const xsScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.only('xs'));
	const showSidebarNavigation = useShowSidebarNavigation();

	const desktopChildren = useMemo(() => {
		if (
			(!currentOrderToday &&
				anyOrderDelivered &&
				isActiveRoute(location.pathname, [
					routes.mySchedule.todaysMedication,
					routes.mySchedule.todaysMedDetails,
					routes.mySchedule.logAsNeededMed,
					routes.mySchedule.manageMedicationReminders,
				])) ||
			(!currentOrderToday && !anyOrderDelivered)
		) {
			return (
				<ContentCard>
					<Typography variant="body1" color="text.secondary">
						{introText}
					</Typography>
				</ContentCard>
			);
		}

		return children;
	}, [anyOrderDelivered, children, currentOrderToday, introText, location]);

	const myScheduleTodaysMedicationWalkThroughData = myScheduleTodaysMedicationWalkThrough();

	if (xsScreen) {
		if (showSidebarNavigation) {
			return (
				<Grid xs={12}>
					<ContentCard>
						<Typography variant="h1" fontWeight="bold" color="text.secondary">
							My Schedule
						</Typography>
						<Typography variant="body1" color="text.primary">
							{introText}
						</Typography>
					</ContentCard>
					<ContentCard>
						<SidebarMenu menuItems={menuItems} />
					</ContentCard>
					<WalkThrough {...myScheduleTodaysMedicationWalkThroughData} />
				</Grid>
			);
		}

		return <Grid xs={12}>{children}</Grid>;
	}

	return (
		<>
			<Grid sm={4}>
				<ContentCard>
					<SidebarMenu menuItems={menuItems} />
				</ContentCard>
			</Grid>
			<Grid sm={8}>{desktopChildren}</Grid>
			<WalkThrough {...myScheduleTodaysMedicationWalkThroughData} />
		</>
	);
}

export default MyScheduleLayout;
