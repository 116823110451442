import React from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import {
	Stack,
	Typography,
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from '@mui/material';
import DownloadPdfButton from 'components/common/inputs/DownloadPdfButton';
import { getPdfAsNeededMedsUsageHistory } from 'core/api/mySchedule/mySchedule.api';
import { IAsNeededMedication } from 'core/api/mySchedule/mySchedule.models';

export function AsNeededMedHistory({ asNeededMedHistory, scheduledMedId }: IAsNeededMedication) {
	const { showBoundary } = useErrorBoundary();

	return (
		<>
			<Stack pb={2} direction="row" justifyContent="space-between" alignItems="center">
				<Typography component="b" fontWeight={700}>
					{asNeededMedHistory.from} - {asNeededMedHistory.to}
				</Typography>
				<DownloadPdfButton
					fetchPdfData={() => getPdfAsNeededMedsUsageHistory({ scheduledMedId })}
					onError={showBoundary}
					variant="text"
					sx={{ fontSize: '14px' }}
				>
					Print Usage Report
				</DownloadPdfButton>
			</Stack>
			<TableContainer component={Paper} sx={{ maxHeight: 240 }}>
				<Table stickyHeader size="small">
					<TableHead>
						<TableRow>
							<TableCell>Date</TableCell>
							<TableCell>Time</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{asNeededMedHistory.asNeededMedsTaken.length > 0 ? (
							asNeededMedHistory.asNeededMedsTaken.map((row) => (
								<TableRow key={row.scheduledMedTakenId}>
									<TableCell>{row.date}</TableCell>
									<TableCell>{row.time}</TableCell>
								</TableRow>
							))
						) : (
							<TableRow>
								<TableCell colSpan={2}>None</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
}

export default AsNeededMedHistory;
