import { MedicationRefillsStatus, MedicationRefillsStatusAlertLevel } from '../../api/medications/medications.models';

export enum DisplayOrderStatus {
	PREPARING_YOUR_SHIPMENT = 'Preparing your shipment',
	ON_ITS_WAY = 'On its way',
	DELIVERED = 'Delivered',
	POSSIBLE_DELIVERY_ISSUE = 'Possible Delivery Issue',
	NA = 'n/a',
}

export enum DeliveryRecordType {
	NON_ROUTINE = 'Non-Routine',
	SHORT_SUPPLY = 'Short Supply',
	ROUTINE = 'Routine',
}

export enum DeliveryNotIncludedActions {
	CALL_YOUR_PRESCRIBER_WITH_QUESTIONS = 'Call Your Prescriber With Questions',
}

export enum DeliveryTrackingAction {
	LINK = 'link',
	LOCAL_DELIVERY = 'local-delivery',
}

export interface IDeliveryMedication {
	sfid: string | null;
	name: string | null;
	imageUrl: string | null;
	rxNumber: string | null;
	refills: number | null;
	dispensed: number | null;
	reason: string | null;
	action: string | null;
	prescriberName: string | null;
	prescriberPhone: string | null;
	refillsStatus: MedicationRefillsStatus | null;
	refillsStatusAlertLevel: MedicationRefillsStatusAlertLevel | null;
}

export interface IDelivery {
	sfid: string | null;
	orderName: string | null;
	orderNumber: string | null;
	orderStatus: DisplayOrderStatus | null;
	trackingButton: ITrackingButton | null;
	orderStartDate: string | null;
	orderEndDate: string | null;
	shipTo: string | null;
	recordType: DeliveryRecordType | null;
	notIncludedMedication: IDeliveryMedication[];
	exactPackMedication: IDeliveryMedication[];
	refrigerateMedication: IDeliveryMedication[];
	additionalMedication: IDeliveryMedication[];
	isFirstExactPack: boolean | null;
	isFirstMonthlyOrder: boolean | null;
}

export interface IUpcomingDelivery
	extends Pick<IDelivery, 'sfid' | 'orderName' | 'orderNumber' | 'orderStatus' | 'recordType' | 'isFirstExactPack'> {
	displayStatus: string | null;
	deliveryDate: string | null;
}

export interface IPastDelivery extends IUpcomingDelivery {}

export interface IDeliveriesSummaryActionPayload extends IDelivery {}

export interface IDeliveriesListActionPayload {
	upcomingDeliveries: IUpcomingDelivery[];
	pastDeliveries: IPastDelivery[];
	shippingAddress: string | null;
	firstRoutineOrderReceived: boolean;
	anyOrderReceived: boolean;
	vialsPatient: boolean;
}

export interface ITrackingButton {
	action: DeliveryTrackingAction | null;
	label: string | null;
	link: string | null;
}

export interface IGetDeliveriesSummaryActionPayload {
	sfid: string;
}
