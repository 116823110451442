import { useQuery } from '@tanstack/react-query';
import { patientPushDeviceList } from 'core/api/admin/patients.api';
import { PatientPushDevice, PatientPushDevicesParams } from 'core/api/admin/patients.models';
import normalizeHydraCollection from 'utils/normalizeHydraCollection';

export function usePatientPushDeviceListQuery({ page, patientId, ...restRequest }: PatientPushDevicesParams) {
	return useQuery({
		queryKey: ['admin', 'patient', 'view', patientId, 'pushDevice', 'list', restRequest, page],
		queryFn: () => patientPushDeviceList({ page, patientId, ...restRequest }),
		select: normalizeHydraCollection<PatientPushDevice>,
		enabled: !!patientId,
	});
}

export default usePatientPushDeviceListQuery;
