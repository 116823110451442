import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function PillExactPackIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 46 38" {...props}>
			<path
				d="M18.7498 36.8498L10.7998 32.9498L10.9498 17.7998"
				stroke="currentColor"
				strokeWidth="2"
				strokeMiterlimit="8"
				fill="none"
			/>
			<path d="M44.7 27.25L18.75 29.65" stroke="currentColor" strokeWidth="2" strokeMiterlimit="8" fill="none" />
			<path
				d="M18.7498 29.6499L10.7998 25.8999"
				stroke="currentColor"
				strokeWidth="2"
				strokeMiterlimit="8"
				fill="none"
			/>
			<path
				d="M44.6998 4.1499L35.5498 1.8999L15.2998 3.8499C15.2998 3.8499 27.5998 3.5499 22.3498 6.5499L44.6998 4.1499ZM44.6998 4.1499V34.1499L18.7498 36.8499V10.5999"
				stroke="currentColor"
				strokeWidth="2"
				strokeMiterlimit="8"
				fill="none"
			/>
			<path
				d="M21.6 8.05L14.25 6.25L2.25 16.75L9 19.6L21.6 7.75L22.35 6.55"
				stroke="currentColor"
				strokeWidth="2"
				strokeMiterlimit="8"
				fill="none"
			/>
			<path
				d="M15.3002 3.69995L13.9502 6.54995"
				stroke="currentColor"
				strokeWidth="2"
				strokeMiterlimit="8"
				fill="none"
			/>
		</SvgIcon>
	);
}
