import { useMemo } from 'react';
import { WalkThroughScreen, WalkThroughScreenKeys, WalkThroughStep } from 'components/patient/walkThrough/WalkThrough';
import { useUserMetaData } from 'hooks/patient';
import { UserMetaDataKeys } from 'core/api/userAccount/userAccount.models';

export const myScheduleTodaysMedicationWalkThrough = (props?: {
	isSkipped: boolean;
	isActive: boolean;
}): WalkThroughScreen => {
	const { listQuery: userMetaDataList } = useUserMetaData();
	const { data: userMetaDataWT } = userMetaDataList({ key: UserMetaDataKeys.WALKTHROUGH });
	const screensCompleted = useMemo(
		() => userMetaDataWT?.items?.[0]?.value.split(',') || [],
		[userMetaDataWT]
	) as WalkThroughScreenKeys[];

	if (!screensCompleted.includes(WalkThroughScreenKeys.MY_SCHEDULE) && !props) {
		return {
			screenKey: [WalkThroughScreenKeys.MY_SCHEDULE],
			steps: [
				{
					target: '.EcaListItem-today’SMedication',
					title: 'Track when you take your medication.',
					content: '',
					disableBeacon: true,
				},
				{
					target: '.EcaListItem-manageMedicationReminders',
					title: 'Turn on medication reminders so you know when it’s time to take your next dose.',
					content: '',
					disableBeacon: true,
					disableOverlayClose: false,
				},
			],
		};
	}

	const screenKey: WalkThroughScreenKeys[] = [];
	const steps: WalkThroughStep[] = [];

	if (screensCompleted.includes(WalkThroughScreenKeys.MY_SCHEDULE)) {
		if (!screensCompleted.includes(WalkThroughScreenKeys.MY_SCHEDULE_TODAY_MEDS_SKIPPED) && !!props?.isSkipped) {
			screenKey.push(WalkThroughScreenKeys.MY_SCHEDULE_TODAY_MEDS_SKIPPED);
		}
		if (!screensCompleted.includes(WalkThroughScreenKeys.MY_SCHEDULE_TODAY_MEDS_ACTIVE) && !!props?.isActive) {
			screenKey.push(WalkThroughScreenKeys.MY_SCHEDULE_TODAY_MEDS_ACTIVE);
		}

		if (
			!screensCompleted.includes(WalkThroughScreenKeys.MY_SCHEDULE_TODAY_MEDS_SKIPPED) &&
			!screensCompleted.includes(WalkThroughScreenKeys.MY_SCHEDULE_TODAY_MEDS_ACTIVE)
		) {
			steps.push({
				target: 'body',
				title: 'Track the medication you have taken for the day.',
				content: '',
				placement: 'center',
				disableBeacon: true,
			});
		}

		if (screenKey.includes(WalkThroughScreenKeys.MY_SCHEDULE_TODAY_MEDS_SKIPPED)) {
			steps.push({
				target: '.EcaLink-ScheduleListItem-passed',
				title: 'Tap an earlier time to mark medications as taken or skipped.',
				content: '',
				disableBeacon: true,
			});
		}

		if (screenKey.includes(WalkThroughScreenKeys.MY_SCHEDULE_TODAY_MEDS_ACTIVE)) {
			steps.push({
				target: '.EcaLink-ScheduleListItem-active',
				title:
					'You can mark that you have taken all your medications or that you skipped one for that time of the day.',
				content: '',
				disableBeacon: true,
			});
		}
	}

	if (steps.length > 0) {
		steps[steps.length - 1].disableOverlayClose = false;
	}

	return {
		screenKey,
		steps,
	} as WalkThroughScreen;
};
