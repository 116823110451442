import React, { useEffect, useState, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { TextField, Alert, Box, Button, Typography, Stack } from '@mui/material';
import Joi from 'joi';
import DateOfBirthField from 'components/common/inputs/DateOfBirthField';
import { ICheckStatusResponse, IVerifyIdentitySearchQuery } from 'core/api/registration/registration.models';
import ReCAPTCHA from 'react-google-recaptcha';
import { useRegisterMutation } from 'hooks/auth/useRegisterMutation';

const schema = Joi.object({
	dateOfBirth: Joi.string().required(),
	lastFourSsn: Joi.string().min(4).max(4).required(),
	zipCode: Joi.string().min(5).max(5).required(),
});

export interface RegisterVerifyYourInfoProps extends Partial<ICheckStatusResponse> {
	onGetHash: (hash: string) => void;
}

export function RegisterVerifyYourInfo({ onGetHash }: RegisterVerifyYourInfoProps) {
	const { verifyIdentity } = useRegisterMutation();
	const { mutate: verifyIdentityMutate, isPending, error: verifyIdentityError } = verifyIdentity;
	const [generalErrors, setGeneralErrors] = useState<string | null>(null);

	const { control, handleSubmit } = useForm<IVerifyIdentitySearchQuery>({
		mode: 'onChange',
		resolver: joiResolver(schema),
	});

	const successCallback = (token: string | null) =>
		handleSubmit((data) =>
			verifyIdentityMutate(
				{
					...data,
					recaptchaToken: token || '',
				},
				{
					onSuccess(identityData) {
						if (identityData?.error) {
							setGeneralErrors(identityData.error || null);
						} else {
							onGetHash(identityData?.hash || '');
						}
					},
				}
			)
		)();

	const recaptchaRef = useRef<ReCAPTCHA>(null);

	const executeCaptcha = (e: { preventDefault: () => void }) => {
		e.preventDefault();
		if (recaptchaRef?.current) {
			recaptchaRef.current.reset();
			recaptchaRef.current.execute();
		}
	};

	useEffect(() => {
		if (verifyIdentityError) {
			setGeneralErrors(verifyIdentityError?.message || 'Something went wrong');
		}
	}, [verifyIdentityError]);

	return (
		<>
			<Typography component="span" variant="h4" fontWeight="bold" color="text.secondary">
				Verify Your Information
			</Typography>
			<Stack gap="24px" component="form" onSubmit={executeCaptcha} sx={{ mt: 2 }}>
				<Box>
					<Typography variant="h2" fontWeight={700} color="text.secondary">
						What is your date of birth?
					</Typography>
					<Stack direction="row" justifyContent="flex-start" mt={1} gap="24px">
						<Controller
							name="dateOfBirth"
							control={control}
							render={({ field }) => <DateOfBirthField {...field} value={field.value || ''} />}
						/>
					</Stack>
				</Box>

				<Controller
					name="lastFourSsn"
					control={control}
					render={({ field: { value, ...restField }, fieldState: { error } }) => (
						<TextField
							{...restField}
							value={value || ''}
							error={!!error}
							label="What are the last four digits of your Social Security Number?"
							variant="standard"
							size="small"
							placeholder="####"
							inputProps={{
								maxLength: 4,
							}}
							sx={{
								'& .MuiInputBase-root': {
									width: '200px',
								},
							}}
						/>
					)}
				/>

				<Controller
					name="zipCode"
					control={control}
					render={({ field: { value, ...restField }, fieldState: { error } }) => (
						<TextField
							{...restField}
							value={value || ''}
							label="What is the ZIP code of the address where your medications are delivered?"
							error={!!error}
							variant="standard"
							size="small"
							placeholder="#####"
							inputProps={{
								maxLength: 5,
							}}
							sx={{
								'& .MuiInputBase-root': {
									width: '200px',
								},
							}}
						/>
					)}
				/>

				{generalErrors && (
					<Alert sx={{ mt: 2 }} severity="error">
						{generalErrors}
					</Alert>
				)}

				<Box sx={{ width: { xs: '100%', sm: '50%' } }}>
					<Button disabled={isPending} variant="contained" color="primary" fullWidth type="submit">
						{isPending ? 'Verifying...' : 'Verify Your Identity'}
					</Button>
				</Box>

				<ReCAPTCHA
					ref={recaptchaRef}
					sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
					onChange={successCallback}
					size="invisible"
				/>
			</Stack>
		</>
	);
}

export default RegisterVerifyYourInfo;
