import React from 'react';
import { Box, Container, Stack } from '@mui/material';
import { AppThemeProvider } from 'themes/AppTheme';

interface AuthLayoutProps {
	children: React.ReactNode;
}

export function AuthLayout({ children }: AuthLayoutProps) {
	return (
		<AppThemeProvider>
			<Box
				sx={{
					minHeight: '100vh',
					backgroundColor: { xs: 'rgba(245, 245, 247, 1)', md: 'hsla(278,100%,6%,1)' },
					backgroundImage: {
						xs: 'radial-gradient(at 51% 3%, hsla(240,11%,96%,0.59) 0px, transparent 50%), radial-gradient(at 24% 72%, hsla(206,80%,43%,0.5) 0px, transparent 50%), radial-gradient(at 77% 99%, hsla(283,100%,28%,0.4) 0px, transparent 50%)',
						md: 'radial-gradient(at 76% 58%, hsla(278,100%,6%,0.81) 0px, transparent 50%), radial-gradient(at 26% 24%, hsla(278,100%,6%,0.81) 0px, transparent 50%), radial-gradient(at 35% 99%, hsla(206,80%,43%,0.62) 0px, transparent 50%), radial-gradient(at 62% 1%, hsla(283,100%,28%,0.71) 0px, transparent 50%)',
					},
				}}
			>
				<Container maxWidth="lg" sx={{ paddingTop: '5%', paddingBottom: '5%' }}>
					<Stack
						sx={{ backgroundColor: { sm: 'transparent', md: 'background.default' } }}
						alignItems="stretch"
						direction="row"
					>
						{children}
					</Stack>
				</Container>
			</Box>
		</AppThemeProvider>
	);
}

export default AuthLayout;
