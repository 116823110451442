import React, { useCallback, useState } from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import Switch from 'components/common/inputs/switch/Switch';
import TimePicker from 'components/common/inputs/TimePicker';
import { IScheduledTimeReminderSettings } from 'core/api/mySchedule/mySchedule.models';
import Suspense from 'components/Suspense';

interface ReminderSettingsProps {
	reminders?: IScheduledTimeReminderSettings[];
	isLoading: boolean;
	onChangeReminder?: (reminder: Partial<IScheduledTimeReminderSettings>) => void;
}

export function ReminderSettings({ reminders, isLoading, onChangeReminder }: ReminderSettingsProps) {
	const [reminderUpdated, setReminderUpdated] = useState<IScheduledTimeReminderSettings | null>(null);

	const handleChangeReminder = useCallback(
		(reminder: IScheduledTimeReminderSettings) => {
			onChangeReminder?.(reminder);
			setReminderUpdated(reminder);
		},
		[onChangeReminder]
	);

	const convertToDateTime = useCallback((hours: number, minutes: number, meridiem: 'AM' | 'PM'): Dayjs => {
		let adjustedHours = hours;

		adjustedHours = Math.max(1, Math.min(12, adjustedHours));

		if (meridiem === 'PM' && hours < 12) {
			adjustedHours += 12;
		} else if (meridiem === 'AM' && hours === 12) {
			adjustedHours = 0;
		}

		return dayjs().hour(adjustedHours).minute(minutes).second(0);
	}, []);

	const getReminderInterval = useCallback(
		({ scheduledTimeHour, scheduledTimeMinute, scheduledTimeMeridiem }: IScheduledTimeReminderSettings) =>
			convertToDateTime(scheduledTimeHour ?? 0, scheduledTimeMinute ?? 0, scheduledTimeMeridiem).format('hh:mm A'),
		[convertToDateTime]
	);

	const handleChangeEnabled = useCallback(
		(reminder: IScheduledTimeReminderSettings, isEnabled: boolean) => {
			handleChangeReminder({
				...reminder,
				enabled: isEnabled,
			});
		},
		[handleChangeReminder]
	);

	const handleTimeChange = useCallback(
		(reminder: IScheduledTimeReminderSettings) => (newTime: Dayjs | null) => {
			if (newTime) {
				let hours = newTime.hour();
				const minutes = newTime.minute();
				const meridiem = hours >= 12 ? 'PM' : 'AM';
				hours %= 12;
				hours = hours === 0 ? 12 : hours;

				handleChangeReminder({
					...reminder,
					reminderTimeHour: hours,
					reminderTimeMinute: minutes,
					reminderTimeMeridiem: meridiem,
				});
			}
		},
		[handleChangeReminder]
	);

	const handleIsLoading = useCallback(
		(reminder: IScheduledTimeReminderSettings) =>
			!!(isLoading && reminderUpdated && getReminderInterval(reminder) === getReminderInterval(reminderUpdated)),
		[isLoading, reminderUpdated, getReminderInterval]
	);

	return (
		<List
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '12px',
			}}
		>
			{reminders &&
				reminders.map((reminder) => (
					<ListItem
						key={getReminderInterval(reminder)}
						sx={{
							padding: '15px 24px',
							gap: '24px',
							borderRadius: '10px',
							background: (theme) => theme.palette.background.secondary,
							minHeight: '110px',
						}}
					>
						<Suspense isLoading={handleIsLoading(reminder)}>
							<ListItemText
								primary={reminder.scheduleTimeOfDayName}
								primaryTypographyProps={{
									variant: 'h2',
									fontWeight: 700,
									color: 'text.secondary',
								}}
								secondary={`Prescribed Time to Take: ${getReminderInterval(reminder)}`}
								secondaryTypographyProps={{
									variant: 'body1',
									color: 'text.secondary',
								}}
							/>
							{!!reminder.enabled && (
								<TimePicker
									label="Current Reminder Time"
									value={convertToDateTime(
										reminder.reminderTimeHour ?? 0,
										reminder.reminderTimeMinute ?? 0,
										reminder.reminderTimeMeridiem
									)}
									onAccept={handleTimeChange(reminder)}
									slotProps={{
										textField: {
											size: 'small',
											sx: {
												'& .MuiInputLabel-standard': {
													fontSize: '1rem',
													lineHeight: '1.5rem',
												},
											},
										},
										field: {
											readOnly: true,
										},
										desktopPaper: {
											elevation: 3,
											sx: {
												background: (theme) => theme.palette.background.default,
												border: '2px solid',
												borderColor: 'primary.main',
											},
										},
									}}
								/>
							)}
							<Switch
								labelOn="On"
								labelOff="Off"
								labelProps={{ labelPlacement: 'start', slotProps: { typography: { color: 'text.secondary' } } }}
								checked={reminder.enabled || false}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
									handleChangeEnabled(reminder, event.target.checked)
								}
							/>
						</Suspense>
					</ListItem>
				))}
		</List>
	);
}

export default ReminderSettings;
