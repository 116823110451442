import React from 'react';
import { Box, Chip, ChipProps } from '@mui/material';
import { MedicationRefillsStatusAlertLevel } from 'core/api/medications/medications.models';

export interface IRefillsStatusChipProps extends Omit<ChipProps, 'label' | 'size' | 'color'> {
	refillsStatusAlertLevel: string | null | undefined;
	refillsStatus: string | null | undefined;
}

function RefillsStatusChip({ refillsStatusAlertLevel, refillsStatus }: IRefillsStatusChipProps) {
	switch (refillsStatusAlertLevel) {
		case MedicationRefillsStatusAlertLevel.REFILLS_ALERT_LEVEL_DANGER:
			return <Chip component="span" label={refillsStatus} size="small" color="error" className="refillsChip" />;
		case MedicationRefillsStatusAlertLevel.REFILLS_ALERT_LEVEL_WARNING:
			return <Chip component="span" label={refillsStatus} size="small" color="warning" className="refillsChip" />;
		default:
			return (
				<Box component="span" className="refillsChip">
					{refillsStatus}
				</Box>
			);
	}
}

export default RefillsStatusChip;
