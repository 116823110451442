import React from 'react';

import { CssBaseline, createTheme } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { lightPalette } from './mode';
import components from './components';
import breakpoints from './breakpoints';
import shadows from './shadows';
import typography from './typography';

const palette = lightPalette;

export const mainTheme = createTheme(
	{
		palette,
		typography,
		shadows,
		breakpoints,
	},
	{ components }
);

export function MainThemeProvider({ children }: { children: React.ReactNode }) {
	return (
		<MuiThemeProvider theme={mainTheme}>
			<CssBaseline />
			{children}
		</MuiThemeProvider>
	);
}
