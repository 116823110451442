import React from 'react';
import { useParams } from 'react-router-dom';
import DeliverySummary from 'components/patient/delivery/deliverySummary';
import DeliveriesMessage from 'components/patient/delivery/DeliveriesMessage';
import { useDeliveries } from 'hooks/patient';
import Suspense from 'components/Suspense';

function DeliveryPage() {
	const { sfid } = useParams();
	const { getDeliveryQuery, getDeliveriesQuery } = useDeliveries();
	const { data: deliverySummary, isLoading: isLoadingDelivery } = getDeliveryQuery({ sfid });
	const { data: deliveriesList, isLoading: isLoadingDeliveriesList } = getDeliveriesQuery();

	if (!sfid) {
		return (
			<Suspense isLoading={isLoadingDeliveriesList}>
				{deliveriesList && <DeliveriesMessage deliveriesList={deliveriesList} variant="elevation" />}
			</Suspense>
		);
	}

	return (
		<Suspense isLoading={isLoadingDelivery && isLoadingDeliveriesList}>
			{deliverySummary && deliveriesList && (
				<DeliverySummary
					{...deliverySummary}
					deliveryDetailsMessageCard={
						<DeliveriesMessage variant="outlined" deliveriesList={deliveriesList} deliverySummary={deliverySummary} />
					}
				/>
			)}
		</Suspense>
	);
}

export default DeliveryPage;
