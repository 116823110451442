import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createUserMetaData, updateUserMetaData } from 'core/api/userAccount/userAccount.api';
import { UserMetaDataParams } from 'core/api/userAccount/userAccount.models';
import { userAccountQueries } from 'queries/patient';

export function useUserMetaData() {
	const queryClient = useQueryClient();

	const listQuery = (props: UserMetaDataParams) => {
		return useQuery(userAccountQueries.userMetaDataList(props));
	};

	const createMutation = useMutation({
		mutationFn: createUserMetaData,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: userAccountQueries.userMetaDataLists().queryKey,
			});
		},
	});

	const updateMutation = useMutation({
		mutationFn: updateUserMetaData,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: userAccountQueries.userMetaDataLists().queryKey,
			});
		},
	});

	return {
		listQuery,
		createMutation,
		updateMutation,
	};
}

export default useUserMetaData;
