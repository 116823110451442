import { Theme } from '@mui/material/styles';

export default {
	styleOverrides: {
		text: ({ theme }: { theme: Theme }) => ({
			textTransform: 'inherit' as const,
			margin: 0,
			padding: 0,
			textDecoration: 'underline',
			textDecorationColor: 'rgba(34, 34, 34, 0.4)',
			justifyContent: 'center',
			verticalAlign: 'baseline',
			minWidth: 'inherit',
			width: 'fit-content',
			fontWeight: 400,
			':hover': {
				backgroundColor: 'transparent',
				textDecoration: 'underline',
				opacity: 1,
			},
			'& .MuiTouchRipple-root': {
				display: 'none',
			},
			'&.MuiButton-textInherit': {
				color: theme.palette.text.secondary,
			},
		}),
		outlined: ({ theme }: { theme: Theme }) => ({
			background: theme.palette.background.default,
		}),
	},
};
