import React, { useState, useCallback, useMemo } from 'react';
import { MutateOptions } from '@tanstack/react-query';
import { Button, Avatar, Box, Grid, Stack, Typography, ListItemIcon, Tooltip, ListItemText } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
	IScheduledMedDetails,
	IScheduledMedication,
	ScheduledMedStatus,
} from 'core/models/mySchedule/mySchedule.models';
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded';
import { MedsGrid, IMedColumn } from 'components/medsGrid';
import ImageWithPreview from 'components/imageWithPreview/imageWithPreview';
import { IRecordTakenRequestParams, IRecordTakenResponse } from 'core/api/mySchedule/mySchedule.models';
import Switch from 'components/common/inputs/switch/Switch';
import { ScheduleIcon, ScheduleTime, ScheduleTitle } from 'components/scheduleList';

interface ScheduledMedicationRow extends IScheduledMedication, Record<string, unknown> {}

interface MedScheduleDetailsProps extends IScheduledMedDetails {
	onLogChange: (
		variables: IRecordTakenRequestParams,
		options?: MutateOptions<IRecordTakenResponse, Error, IRecordTakenRequestParams, unknown> | undefined
	) => void;
	onBackButton?: () => void;
}

export function MedScheduleDetails({ onLogChange, onBackButton, ...scheduleDetails }: MedScheduleDetailsProps) {
	const {
		scheduleInfo,
		status,
		type,
		title,
		timeOfDaySuffix,
		exactPackMedication: initialExactPackMedication,
		additionalMedication: initialAdditionalMedication,
		monthlyMedication: initialMonthlyMedication,
	} = scheduleDetails;
	const [exactPackMedication, setExactPackMedication] = useState<IScheduledMedication[]>(initialExactPackMedication);
	const [additionalMedication, setAdditionalMedication] = useState<IScheduledMedication[]>(initialAdditionalMedication);
	const [monthlyMedication, setMonthlyMedication] = useState<IScheduledMedication[]>(initialMonthlyMedication);
	const [newStatus, setNewStatus] = useState<ScheduledMedStatus | null>(status);

	const readOnly = status === ScheduledMedStatus.Disabled;

	const getStatus = (taken: boolean | null, skipped: boolean | null): boolean => {
		if (taken === true) return true;
		if (skipped === true) return false;

		return true;
	};
	const getSkippedIds = (medications: IScheduledMedication[]): number[] => {
		return medications.filter((med) => med.skipped === true).map((med) => med.id);
	};
	const initialCombinedMedications: IScheduledMedication[] = useMemo(
		() => initialExactPackMedication.concat(initialAdditionalMedication).concat(initialMonthlyMedication),
		[initialExactPackMedication, initialAdditionalMedication, initialMonthlyMedication]
	);
	const skippedIds = useMemo(
		() =>
			getSkippedIds(exactPackMedication)
				.concat(getSkippedIds(additionalMedication))
				.concat(getSkippedIds(monthlyMedication)),
		[exactPackMedication, additionalMedication, monthlyMedication]
	);
	const onChangeExactPackMedication = useCallback(
		(id: number, taken: boolean) => {
			setExactPackMedication((prevMedications) =>
				prevMedications.map((med) => {
					if (med.id === id) {
						return { ...med, taken, skipped: !taken };
					}
					return med;
				})
			);
			setNewStatus(null);
		},
		[setExactPackMedication, setNewStatus]
	);
	const onChangeAdditionalMedication = useCallback(
		(id: number, taken: boolean) => {
			setAdditionalMedication((prevMedications) =>
				prevMedications.map((med) => {
					if (med.id === id) {
						return { ...med, taken, skipped: !taken };
					}
					return med;
				})
			);
			setNewStatus(null);
		},
		[setAdditionalMedication, setNewStatus]
	);
	const onChangeMonthlyMedication = useCallback(
		(id: number, taken: boolean) => {
			setMonthlyMedication((prevMedications) =>
				prevMedications.map((med) => {
					if (med.id === id) {
						return { ...med, taken, skipped: !taken };
					}
					return med;
				})
			);
			setNewStatus(null);
		},
		[setMonthlyMedication, setNewStatus]
	);
	const handleLogChange = useCallback(() => {
		const allIds = initialCombinedMedications.map((med) => med.id);
		const newTakenIds = allIds.filter((id) => !skippedIds.includes(id));
		onLogChange({ takenIds: newTakenIds, skippedIds });
		setNewStatus(ScheduledMedStatus.Logged);
	}, [initialCombinedMedications, skippedIds]);

	const getColumns = (
		tableName: string,
		onChangeMed: (id: number, taken: boolean) => void
	): IMedColumn<IScheduledMedication>[] => [
		{
			field: 'name',
			headerName: tableName,
			width: 4,
			renderCell:
				({ imageUrl, name, id, taken, skipped }) =>
				() => (
					<Stack direction="row" gap={1} alignItems="center">
						{imageUrl && name && (
							<ImageWithPreview source={imageUrl} title={name}>
								<Avatar alt={name} src={imageUrl} sx={{ width: 60, height: 60 }} variant="rounded" />
							</ImageWithPreview>
						)}
						<Stack direction="column" alignItems="left">
							<Box fontWeight={700}>{name}</Box>
							<Switch
								labelOff="Skipped"
								labelOn="Taken"
								disabled={readOnly}
								checked={getStatus(taken, skipped)}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									onChangeMed(id, event.target.checked);
								}}
							/>
						</Stack>
					</Stack>
				),
		},
	];

	return (
		<Stack useFlexGap spacing={2} direction="column">
			{onBackButton && (
				<Button
					onClick={onBackButton}
					variant="text"
					sx={{ color: 'secondary.main', '&.MuiButton-text': { textDecoration: 'none' } }}
				>
					<ArrowBackIosIcon sx={{ fontSize: (theme) => theme.typography.body1.fontSize }} />
					Back to Today’s Medication
				</Button>
			)}

			<Stack direction="row" gap={2} alignItems="center">
				<ListItemIcon
					sx={{
						minWidth: 'unset',
						'& .MuiSvgIcon-root': {
							fontSize: { xs: '1.875rem', sm: '2.25rem' },
						},
					}}
				>
					<ScheduleIcon {...{ type, status }} />
				</ListItemIcon>

				<ListItemText
					sx={{
						display: 'flex',
						flexDirection: 'column',
						margin: 0,
					}}
					disableTypography
					primary={<ScheduleTitle color="text.secondary">{title}</ScheduleTitle>}
					secondary={
						<ScheduleTime color={status === ScheduledMedStatus.Active ? 'text.secondary' : 'text.primary'}>
							{scheduleInfo}{' '}
							{timeOfDaySuffix && (
								<Tooltip
									placement="right"
									title={
										<>
											<b>Time-sensitive.</b>
											<br />
											Follow the schedule exactly as prescribed.
										</>
									}
								>
									<ScheduleRoundedIcon color="warning" />
								</Tooltip>
							)}
						</ScheduleTime>
					}
				/>
			</Stack>

			<Typography variant="body1" component="p">
				<Typography component="span" fontWeight={600} color="text.secondary">
					Take your medication listed below.
				</Typography>{' '}
				<br />
				Click the button next to a medication if you can’t take it or need to skip it. When finished, click ‘Save’.
			</Typography>

			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Button
						onClick={handleLogChange}
						disabled={readOnly}
						color={newStatus === ScheduledMedStatus.Logged ? 'success' : 'primary'}
						variant="contained"
					>
						{newStatus !== ScheduledMedStatus.Logged && (skippedIds.length < 1 ? 'Log All as Taken' : 'Save')}
						{newStatus === ScheduledMedStatus.Logged && 'Logged'}
					</Button>
				</Grid>
				<Grid item xs={12} md={6}>
					{exactPackMedication && exactPackMedication.length > 0 && (
						<MedsGrid<ScheduledMedicationRow>
							getRowId={(row) => row.id.toString() || ''}
							columns={getColumns('In your ExactPack', onChangeExactPackMedication)}
							rows={exactPackMedication as ScheduledMedicationRow[]}
						/>
					)}
				</Grid>
				<Grid item container xs={12} md={6} gap={2} flexDirection="column" alignItems="center">
					{additionalMedication && additionalMedication.length > 0 && (
						<MedsGrid<ScheduledMedicationRow>
							getRowId={(row) => row.id.toString() || ''}
							columns={getColumns('Not In Your ExactPack', onChangeAdditionalMedication)}
							rows={additionalMedication as ScheduledMedicationRow[]}
						/>
					)}
				</Grid>
				<Grid item container xs={12} md={6} gap={2} flexDirection="column" alignItems="center">
					{monthlyMedication && monthlyMedication.length > 0 && (
						<MedsGrid<ScheduledMedicationRow>
							getRowId={(row) => row.id.toString() || ''}
							columns={getColumns('Monthly Order', onChangeMonthlyMedication)}
							rows={monthlyMedication as ScheduledMedicationRow[]}
						/>
					)}
				</Grid>
			</Grid>
		</Stack>
	);
}

export default MedScheduleDetails;
