import React, { useState } from 'react';
import { Box, Collapse, TableCell, TableRow } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { AnyObject } from 'shared/types';
import { IMedColumn } from './MedColumn';

interface IMedRowProps<ROW> {
	row: ROW;
	columns: IMedColumn<ROW>[];
	getCollapseData?: (row: ROW) => React.ReactNode;
	collapseButton?: string;
}

export function MedRow<ROW extends AnyObject>({ columns, row, getCollapseData, collapseButton }: IMedRowProps<ROW>) {
	const [isOpenCollapse, setOpenCollapse] = useState(false);

	const isFieldValid = (field: string) => field && row && row[field] !== undefined;

	const tableCell = ({ field, hide, width, ...rest }: IMedColumn<ROW>) => {
		if (!hide) {
			if (isFieldValid(field)) {
				const cellValue = row[field];
				const CellView = rest.renderCell ? rest.renderCell(row) : () => cellValue as JSX.Element;

				const sxTableCell = {
					width,
				};

				return (
					<TableCell key={`${field}_${cellValue}_cell`} sx={sxTableCell}>
						<CellView />
					</TableCell>
				);
			}
			if (rest.getActions) {
				const ActionComponent = rest.getActions(row);
				return (
					<TableCell key={`${field}_actions_cell`}>
						<ActionComponent />
					</TableCell>
				);
			}
		}

		return null;
	};

	return (
		<>
			<TableRow key="short_info" className="EcaMedTableRow-root">
				{columns.map(tableCell)}
			</TableRow>
			{getCollapseData && (
				<TableRow key="collapse">
					<TableCell className="collapseBody">
						<Box className="collapseButton" onClick={() => setOpenCollapse(!isOpenCollapse)}>
							{isOpenCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
							{collapseButton || 'Show more'}
						</Box>
						<Collapse in={isOpenCollapse} timeout="auto" unmountOnExit>
							{getCollapseData(row)}
						</Collapse>
					</TableCell>
				</TableRow>
			)}
		</>
	);
}

export default MedRow;
