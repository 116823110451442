import React from 'react';
import { Button, Grid, Stack } from '@mui/material';
import isActiveRoute from 'utils/isActiveRoute';
import { LoaderFunctionArgs, useLocation, Link as RouterLink, generatePath, useParams } from 'react-router-dom';
import routes from 'routes';
import { adminPatientQueries } from 'queries/admin';
import { QueryClient } from '@tanstack/react-query';

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		const { patientId } = params;
		const query = adminPatientQueries.patient({ id: patientId });
		return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query));
	};

interface AdminPatientLayoutProps {
	children: React.ReactNode;
}

export default function AdminPatientLayout({ children }: AdminPatientLayoutProps) {
	const location = useLocation();
	const { patientId } = useParams();

	const navItems: { to: string; activeRoutes: string[]; label: string }[] = [
		{
			to: generatePath(routes.admin.patients.patient.medSync, {
				patientId,
			}),
			activeRoutes: [routes.admin.patients.patient.medSync],
			label: 'Med Sync',
		},
		{
			to: generatePath(routes.admin.patients.patient.notifications, {
				patientId,
			}),
			activeRoutes: [routes.admin.patients.patient.notifications],
			label: 'Notifications',
		},
		{
			to: generatePath(routes.admin.patients.patient.metaData, {
				patientId,
			}),
			activeRoutes: [routes.admin.patients.patient.metaData],
			label: 'Meta Data',
		},
		{
			to: generatePath(routes.admin.patients.patient.pushDevices, {
				patientId,
			}),
			activeRoutes: [routes.admin.patients.patient.pushDevices],
			label: 'Push Devices',
		},
	];

	return (
		<Grid item container xs={12} spacing={2} alignItems="flex-start" sx={{ marginTop: 0, paddingTop: '0 !important' }}>
			<Grid item xs={12}>
				<Stack direction="row" gap="12px">
					{navItems.map(({ to, activeRoutes, label }) => (
						<Button
							component={RouterLink}
							to={to}
							key={label}
							variant={isActiveRoute(location.pathname, activeRoutes) ? 'contained' : 'outlined'}
						>
							{label}
						</Button>
					))}
				</Stack>
			</Grid>

			{children}
		</Grid>
	);
}
