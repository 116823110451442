import React from 'react';
import { IScheduledMedDetails, ScheduledMedStatus, ScheduledMedType } from 'core/models/mySchedule/mySchedule.models';
import { MorningIcon } from 'components/icons/MorningIcon';
import { AfternoonIcon } from 'components/icons/AfternoonIcon';
import { LateAfternoonIcon } from 'components/icons/LateAfternoonIcon';
import { EveningIcon } from 'components/icons/EveningIcon';
import { BedtimeIcon } from 'components/icons/BedtimeIcon';
import { EarlyMorningIcon } from 'components/icons/EarlyMorningIcon';
import { LateMorningIcon } from 'components/icons/LateMorningIcon';
import { NoonIcon } from 'components/icons/NoonIcon';

interface IScheduleIconProps extends Pick<IScheduledMedDetails, 'type' | 'status'> {}

export function ScheduleIcon({ status, type }: IScheduleIconProps) {
	const scheduleIcons = {
		[ScheduledMedType.Midnight]: BedtimeIcon,
		[ScheduledMedType.EarlyMorning]: EarlyMorningIcon,
		[ScheduledMedType.Morning]: MorningIcon,
		[ScheduledMedType.LateMorning]: LateMorningIcon,
		[ScheduledMedType.Noon]: NoonIcon,
		[ScheduledMedType.Afternoon]: AfternoonIcon,
		[ScheduledMedType.LateAfternoon]: LateAfternoonIcon,
		[ScheduledMedType.Evening]: EveningIcon,
		[ScheduledMedType.Bedtime]: BedtimeIcon,
	};

	const IconByType = scheduleIcons[type];

	return (
		<IconByType
			sx={{
				color: (theme) => (status === ScheduledMedStatus.Active ? theme.palette.primary.main : theme.palette.grey[500]),
			}}
		/>
	);
}
