import React from 'react';
import { ListItem, ListItemButton, Stack, styled, Chip } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { generatePath, Link as RouterLink, useLocation } from 'react-router-dom';
import { InboxNotification } from 'core/api/inboxNotification';
import relativeDateFormat from 'utils/relativeDateFormat';
import routes from 'routes';
import MessageSummary from '../MessageSummary';

const NoMessages = styled(ListItem)(({ theme }) => ({
	alignItems: 'stretch',
	gap: '15px',
	borderTop: '1px solid',
	borderColor: theme.palette.divider,
}));

const MessageItemButton = styled(ListItemButton)<{ component: React.ElementType; to?: string; state?: any }>(
	({ theme }) => ({
		alignItems: 'stretch',
		gap: '15px',
		padding: '24px 16px',
		borderTop: '1px solid',
		borderColor: theme.palette.divider,
	})
);

const MessageTime = styled(Stack)(({ theme }) => ({
	color: theme.palette.grey[600],
	fontWeight: 400,
	textAlign: 'left',
	justifyContent: 'center',
	flexDirection: 'row',
	alignItems: 'center',
	fontSize: '1rem',
	lineHeight: '1.5rem',
	[theme.breakpoints.down('sm')]: {
		fontSize: '0.875rem',
		lineHeight: '1rem',
	},
}));

interface InboxMessagesListProps {
	messages: InboxNotification[];
}

export function InboxMessagesList({ messages }: InboxMessagesListProps) {
	const location = useLocation();

	if (!messages.length) {
		return <NoMessages>You have no messages at this time.</NoMessages>;
	}

	return messages.map(({ id, readAt, subject, preview, createdAt }) => (
		<MessageItemButton
			component={RouterLink}
			key={id}
			to={generatePath(routes.inbox.item, {
				messageId: id,
			})}
			state={{ backTo: location }}
		>
			<MessageSummary primary={subject} secondary={preview} />
			<Stack gap={1} alignItems="flex-end">
				<MessageTime>
					{relativeDateFormat(createdAt)} <KeyboardArrowRightIcon />
				</MessageTime>
				{!readAt && <Chip label="New" color="primary" size="small" sx={{ px: '16px' }} />}
			</Stack>
		</MessageItemButton>
	));
}

export default InboxMessagesList;
