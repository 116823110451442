import { queryOptions } from '@tanstack/react-query';
import { getUserMetaDataList } from 'core/api/userAccount/userAccount.api';
import { UserMetaData, UserMetaDataParams } from 'core/api/userAccount/userAccount.models';
import normalizeHydraCollection from 'utils/normalizeHydraCollection';

export const userAccountQueries = {
	userMetaData: queryOptions({ queryKey: ['userMetaData'] as const, queryFn: () => [] as const }),
	userMetaDataLists: () =>
		queryOptions({
			queryKey: [...userAccountQueries.userMetaData.queryKey, 'lists'] as const,
			queryFn: () => [] as const,
		}),
	userMetaDataList: (request?: UserMetaDataParams) =>
		queryOptions({
			queryKey: [...userAccountQueries.userMetaDataLists().queryKey, 'list', request] as const,
			queryFn: () => getUserMetaDataList(request),
			select: normalizeHydraCollection<UserMetaData>,
		}),
};
