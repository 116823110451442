import React from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { WalkThroughScreen, WalkThroughScreenKeys } from 'components/patient/walkThrough/WalkThrough';

export const medicationsWalkThrough: WalkThroughScreen = {
	screenKey: WalkThroughScreenKeys.MEDICATIONS,
	steps: [
		{
			target: 'body',
			title: 'Click on the medications we have on file for you to learn side effects, how to take it, and more!',
			content: 'For new patients, medications will be added as we receive them from your prescribers.',
			placement: 'center',
			showProgress: true,
		},
		{
			target: '.EcaListItem-refillAlerts',
			title: 'View important refill alerts.',
			content: (
				<>
					<ErrorIcon color="error" sx={{ fontSize: '2.25rem' }} />
					You’ll see this icon when we need you to contact your prescriber.
				</>
			),
			disableOverlayClose: false,
		},
	],
};
