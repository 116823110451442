import React from 'react';
import { Grid } from '@mui/material';
import { PatientPushDeviceList } from 'components/admin';
import { useParams } from 'react-router-dom';
import withPageContext from 'hoc/withPageContext';

function PatientsPushDevicesPage() {
	const { patientId } = useParams();

	return (
		<Grid item xs={12}>
			<PatientPushDeviceList userId={Number(patientId)} />
		</Grid>
	);
}

export default withPageContext(PatientsPushDevicesPage);
