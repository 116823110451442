import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import useDownloadPdf from 'hooks/common/useDownloadPdf';

interface DownloadPdfButtonProps extends Omit<ButtonProps, 'children' | 'onError'> {
	children: React.ReactNode;
	fetchPdfData: () => Promise<Blob>;
	onError?: (error: ERROR) => void;
	onSuccess?: () => void;
}

export enum ERROR {
	POPUP_BLOCKER_ERROR = 'Please disable your popup blocker to view the PDF.',
	PDF_DOWNLOAD_ERROR = 'An error occurred while downloading the PDF.',
}

function DownloadPdfButton({
	children,
	fetchPdfData,
	onSuccess,
	onError,
	onClick,
	...buttonArgs
}: DownloadPdfButtonProps) {
	const { isLoading, downloadPdfAction } = useDownloadPdf({ fetchPdfData });

	const handleDownloadPdf = (event: React.MouseEvent<HTMLButtonElement>) => {
		onClick?.(event);
		downloadPdfAction({ onSuccess, onError });
	};

	return (
		<Button disabled={isLoading} onClick={handleDownloadPdf} {...buttonArgs}>
			{isLoading ? 'Loading...' : children}
		</Button>
	);
}

export default DownloadPdfButton;
