import React, { useState, useMemo, useCallback, forwardRef, useRef, useImperativeHandle } from 'react';
import { TextFieldProps } from '@mui/material';
import MonthPicker from 'components/common/inputs/MonthPicker';
import DayPicker from 'components/common/inputs/DayPicker';
import YearPicker from 'components/common/inputs/YearPicker';
import dayjs from 'dayjs';

type DateOfBirthFieldProps = {
	value?: string | null;
} & Omit<TextFieldProps, 'value'>;

type DateOfBirthFieldRef = {
	focus: () => void;
	focusMonth: () => void;
	focusDay: () => void;
	focusYear: () => void;
};

export const DateOfBirthField = forwardRef<DateOfBirthFieldRef, DateOfBirthFieldProps>(
	({ value, onChange, size, variant }, ref) => {
		const [internalValue, setInternalValue] = useState<string | null>(null);
		const isControlled = value !== undefined && onChange !== undefined;
		const [year, month, day] = useMemo(() => {
			const selectedDate = isControlled ? value : internalValue;

			if (selectedDate) {
				return selectedDate.split('-').map((part) => {
					const num = Number(part);
					return Number.isNaN(num) ? null : num;
				});
			}

			return [null, null, null];
		}, [isControlled, value, internalValue]);

		const maxDate = useMemo(() => {
			const currentDate = dayjs();
			const selectedDate = dayjs(`${year}-${month}-${day}`);

			if (!selectedDate.isValid()) {
				return currentDate;
			}

			const newSelectedDate = selectedDate.set('year', currentDate.year());

			if (newSelectedDate.isAfter(currentDate)) {
				return dayjs()
					.set('year', currentDate.year() - 1)
					.set('month', 11)
					.set('date', 31);
			}

			return currentDate;
		}, [year, month, day]);

		const monthPickerRef = useRef<HTMLInputElement>(null);
		const dayPickerRef = useRef<HTMLInputElement>(null);
		const yearPickerRef = useRef<HTMLInputElement>(null);

		const updateDateOfBirth = useCallback(
			(newDay: number | null, newMonth: number | null, newYear: number | null) => {
				if (isControlled && onChange) {
					onChange({
						target: { value: `${newYear}-${newMonth}-${newDay}` },
					} as React.ChangeEvent<HTMLInputElement>);
				} else {
					setInternalValue(`${newYear}-${newMonth}-${newDay}`);
				}
			},
			[onChange, setInternalValue]
		);

		const handleDayChange = useCallback(
			(newDay: number | null) => {
				updateDateOfBirth(newDay, month, year);
			},
			[month, year, updateDateOfBirth]
		);

		const handleMonthChange = useCallback(
			(newMonth: number | null) => {
				updateDateOfBirth(day, newMonth, year);
			},
			[day, year, updateDateOfBirth]
		);

		const handleYearChange = useCallback(
			(newYear: number | null) => {
				updateDateOfBirth(day, month, newYear);
			},
			[day, month, updateDateOfBirth]
		);

		useImperativeHandle(ref, () => ({
			focus: () => {},
			focusMonth: () => {
				monthPickerRef.current?.focus();
			},
			focusDay: () => {
				dayPickerRef.current?.focus();
			},
			focusYear: () => {
				yearPickerRef.current?.focus();
			},
		}));

		return (
			<>
				<MonthPicker
					label="Month"
					value={month}
					ref={monthPickerRef}
					onChange={handleMonthChange}
					slotProps={{
						textField: {
							size,
							variant,
							sx: {
								width: '100px',
								'& .MuiFormLabel-root': {
									fontWeight: 400,
								},
							},
						},
					}}
				/>
				<DayPicker
					label="Day"
					value={day}
					ref={dayPickerRef}
					onChange={handleDayChange}
					slotProps={{
						textField: {
							size,
							variant,
							sx: {
								width: '100px',
								'& .MuiFormLabel-root': {
									fontWeight: 400,
								},
							},
						},
					}}
				/>
				<YearPicker
					label="Year"
					value={year}
					ref={yearPickerRef}
					onChange={handleYearChange}
					maxDate={maxDate}
					slotProps={{
						textField: {
							size,
							variant,
							sx: {
								width: '100px',
								'& .MuiFormLabel-root': {
									fontWeight: 400,
								},
							},
						},
					}}
				/>
			</>
		);
	}
);

export default DateOfBirthField;
