import React, { useState, forwardRef, useMemo, useImperativeHandle } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Paper, Popper, Stack, ClickAwayListener } from '@mui/material';
import { DateField, DateFieldProps, YearCalendar } from '@mui/x-date-pickers';
import { FieldChangeHandlerContext } from '@mui/x-date-pickers/internals/hooks/useField';

interface YearPickerProps extends Omit<DateFieldProps<Dayjs>, 'value' | 'onChange'> {
	value?: number | null;
	onChange?: (newValue: number | null) => void;
}

export const YearPicker = forwardRef(({ value, onChange, ...restProps }: YearPickerProps, ref) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [internalValue, setInternalValue] = useState<number | null>(null);
	const [open, setOpen] = useState(false);

	const isControlled = value !== undefined && onChange !== undefined;
	const selectedYearObj = useMemo(() => {
		const selectedYear = isControlled ? value : internalValue;

		if (selectedYear) {
			return dayjs('1964-01-01').year(selectedYear);
		}

		return null;
	}, [isControlled, value, internalValue]);

	const handleOpenPopper = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
		setOpen(true);
	};

	const handleClosePopper = () => {
		setOpen(false);
	};

	const handleYearChange = (newYear: Dayjs | null, context?: FieldChangeHandlerContext<unknown>) => {
		const year = newYear?.isValid() && !context?.validationError ? Number(newYear?.format('YYYY')) : null;
		if (isControlled && onChange) {
			onChange(year);
		} else {
			setInternalValue(year);
		}
	};

	const handleYearPick = (newYear: Dayjs | null) => {
		handleYearChange(newYear);
		handleClosePopper();
	};

	useImperativeHandle(ref, () => ({
		focus: () => setOpen(true),
		openPicker: () => setOpen(true),
		closePicker: () => setOpen(false),
	}));

	return (
		<ClickAwayListener onClickAway={handleClosePopper}>
			<Stack
				onBlur={(event) => {
					if (!event.currentTarget?.contains(event.relatedTarget)) {
						handleClosePopper();
					}
				}}
			>
				<DateField
					value={selectedYearObj}
					format="YYYY"
					onClick={handleOpenPopper}
					onChange={handleYearChange}
					{...restProps}
				/>
				<Popper
					disablePortal
					open={open}
					anchorEl={anchorEl}
					placement="bottom-start"
					sx={{
						zIndex: (theme) => theme.zIndex.modal,
					}}
				>
					<Paper>
						<YearCalendar value={selectedYearObj} onChange={handleYearPick} maxDate={restProps?.maxDate} />
					</Paper>
				</Popper>
			</Stack>
		</ClickAwayListener>
	);
});

export default YearPicker;
