import React from 'react';
import InfoCardWidget from 'components/InfoCardWidget';
import { Link as RouterLink } from 'react-router-dom';
import routes from 'routes';
import { MedicationScheduleIcon } from 'components/icons/MedicationScheduleIcon';
import { SidebarListItem } from 'components/sidebarList';

export function TodaysMedicationWidget() {
	return (
		<SidebarListItem
			to={routes.mySchedule.todaysMedication}
			component={RouterLink}
			className="EcaListItem-todaysMedication"
		>
			<InfoCardWidget title="Today’s Medication" icon={<MedicationScheduleIcon />} />
		</SidebarListItem>
	);
}

export default TodaysMedicationWidget;
