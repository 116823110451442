export enum ScheduledMedType {
	Midnight = 'midnight',
	EarlyMorning = 'earlyMorning',
	Morning = 'morning',
	LateMorning = 'lateMorning',
	Noon = 'noon',
	Afternoon = 'afternoon',
	LateAfternoon = 'lateAfternoon',
	Evening = 'evening',
	Bedtime = 'bedtime',
}

export enum ScheduledMedStatus {
	Skipped = 'skipped', // deprecated
	Taken = 'taken', // deprecated
	Active = 'active',
	Disabled = 'disabled',
	Passed = 'passed',
	Logged = 'logged',
}

export interface IScheduledMedication {
	id: number;
	name: string;
	imageUrl: string;
	taken: boolean | null;
	skipped: boolean | null;
}

export interface IScheduledMedDetails {
	exactPackMedication: IScheduledMedication[];
	additionalMedication: IScheduledMedication[];
	monthlyMedication: IScheduledMedication[];
	id: number;
	title: string;
	type: ScheduledMedType;
	timeOfDaySuffix: number;
	scheduleInfo: string | null;
	logged: boolean;
	status: ScheduledMedStatus | null;
	message: string | null;
}

export interface ISchedules {
	schedules: IScheduledMedDetails[];
	total: number;
}

export interface IScheduleListRequestPayload {
	day?: string;
}

export interface IRecordTakenRequestPayload {
	takenIds: number[];
	skippedIds: number[];
}

export interface IAsNeededMedsTaken {
	scheduledMedTakenId: number;
	date: string;
	time: string;
}

export interface IAsNeededMedHistory {
	from: string;
	to: string;
	asNeededMedsTaken: IAsNeededMedsTaken[];
}

export interface IAsNeededMed {
	scheduledMedId: number;
	scheduledTimeId: number;
	name: string;
	imageUrl: string;
	howToUse: string;
	lastTakenAt: string;
	warnIfTakenBefore: string;
	asNeededMedHistory: IAsNeededMedHistory;
}

export interface IRecordTakenAsNeededMedRequestPayload {
	scheduledMedId: number | null;
	scheduledTimeId: number | null;
}

export interface IScheduledTimeReminderSettings {
	id: number | null;
	scheduledTimeHour: number | null;
	scheduledTimeMinute: number | null;
	scheduledTimeMeridiem: 'AM' | 'PM';
	reminderTimeHour: number | null;
	reminderTimeMinute: number | null;
	reminderTimeMeridiem: 'AM' | 'PM';
	enabled: boolean | null;
	scheduleTimeOfDayName: string | null;
}

export interface IScheduledTimeReminderSettingsRequestPayload extends Partial<IScheduledTimeReminderSettings> {}
