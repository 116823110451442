import { Theme } from '@mui/material/styles';

export default {
	defaultProps: {
		color: 'success',
	},
	styleOverrides: {
		root: {
			padding: 8,

			'& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
				opacity: 1,
			},
		},
		track: {
			borderRadius: 22 / 2,
		},
		thumb: ({ theme }: { theme: Theme }) => ({
			boxShadow: 'none',
			width: 16,
			height: 16,
			margin: 2,
			backgroundColor: theme.palette.background.default,
		}),
	},
};
