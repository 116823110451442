import React, { useEffect, useState } from 'react';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

type SearchFilterProps = {
	name: string;
	label: string;
	value?: string;
} & TextFieldProps;

export default function SearchFilter({ name, label, value, ...restTextFieldProps }: SearchFilterProps) {
	const [searchValue, setSearchValue] = useState<string>(value || '');

	useEffect(() => {
		if (searchValue && searchValue.length >= 3) {
			restTextFieldProps?.onChange?.({
				target: { value: searchValue },
			} as React.ChangeEvent<HTMLInputElement>);
		}
	}, [searchValue]);

	useEffect(() => {
		if (!value) {
			setSearchValue('');
		}
	}, [value]);

	return (
		<TextField
			{...restTextFieldProps}
			label={label}
			value={searchValue}
			onChange={(event) => setSearchValue(event.target.value)}
			InputProps={{
				name,
				startAdornment: (
					<InputAdornment position="start">
						<SearchOutlinedIcon />
					</InputAdornment>
				),
				...restTextFieldProps?.InputProps,
			}}
		/>
	);
}
