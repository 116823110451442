import React from 'react';
import { Stepper as MuiStepper, Step, StepLabel, StepperProps as MuiStepperProps } from '@mui/material';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';

export interface IStepperProps extends MuiStepperProps {
	steps: string[];
}

function Stepper({ steps, ...rest }: IStepperProps) {
	const existingTheme = useTheme();
	const newTheme = createTheme({
		...existingTheme,
		components: {
			...existingTheme.components,
			MuiStepper: {
				styleOverrides: {
					root: {
						'& .MuiStepLabel-label': {
							fontWeight: 700,
							fontSize: '1rem',
						},
						'& .MuiStepLabel-label.Mui-active': {
							color: existingTheme.palette.primary.main,
						},
						'& .MuiStepLabel-label.Mui-completed': {
							color: existingTheme.palette.primary.main,
						},
						'& .MuiStepLabel-label.Mui-disabled': {
							color: existingTheme.palette.grey[500],
						},
						'& .MuiStepIcon-root': {
							color: 'transparent',
							borderRadius: '50%',
							border: `2px solid ${existingTheme.palette.grey[500]}`,
							fontSize: '2rem',
						},
						'& .MuiStepIcon-root.Mui-active': {
							color: 'transparent',
							borderColor: existingTheme.palette.primary.main,
						},
						'& .MuiStepIcon-root.Mui-completed': {
							color: existingTheme.palette.primary.main,
							borderColor: existingTheme.palette.primary.main,
						},
						'& .MuiStepIcon-text': {
							fontWeight: 700,
							fill: existingTheme.palette.grey[500],
						},
						'& .Mui-active .MuiStepIcon-text': {
							fill: existingTheme.palette.primary.main,
						},
						'& .MuiStepConnector-lineVertical': {
							borderLeftStyle: 'dashed',
							borderLeftWidth: '2px',
						},
						'& .Mui-completed .MuiStepConnector-lineVertical, & .Mui-active .MuiStepConnector-lineVertical': {
							borderColor: existingTheme.palette.primary.main,
						},
					},
				},
			},
		},
	});

	return (
		<ThemeProvider theme={newTheme}>
			<MuiStepper {...rest}>
				{steps.map((label) => (
					<Step key={label}>
						<StepLabel>{label}</StepLabel>
					</Step>
				))}
			</MuiStepper>
		</ThemeProvider>
	);
}
export default Stepper;
