import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function PrintIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 36 36" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M29.04 10.5H27V7.5V7.5C27.0678 5.91452 25.8403 4.57294 24.255 4.5H11.745V4.5C10.1598 4.57294 8.93223 5.91451 9.00003 7.5V10.5H6.96003C4.76227 10.5165 2.9917 12.3072 3.00003 14.505V24.495L3.00003 24.495C2.99172 26.6928 4.7623 28.4835 6.96004 28.5H8.25004V28.5C8.25004 30.1569 9.59319 31.5 11.25 31.5H24.75C26.4069 31.5 27.75 30.1569 27.75 28.5H29.04C31.2378 28.4835 33.0083 26.6928 33 24.495V14.505V14.505C33.0083 12.3072 31.2378 10.5165 29.04 10.5L29.04 10.5ZM12 7.5H24V10.5H12V7.5ZM11.25 28.5V22.5H24.75V28.5H11.25ZM30 24.495V24.495C30.0085 25.0361 29.5809 25.4837 29.04 25.5H27.75V22.5C27.75 20.8431 26.4069 19.5 24.75 19.5H11.25C9.59318 19.5 8.25004 20.8431 8.25004 22.5V25.5H6.96004C6.41915 25.4837 5.99159 25.0361 6.00004 24.495V14.505V14.505C5.99159 13.9639 6.41915 13.5163 6.96004 13.5H29.04C29.5809 13.5163 30.0085 13.9639 30 14.505V24.495Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
