import React, { useCallback, useMemo } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { Link, Typography } from '@mui/material';
import { getPdfScheduledMeds } from 'core/api/mySchedule/mySchedule.api';
import ContentCard from 'components/card/ContentCard';
import DownloadPdfButton from 'components/common/inputs/DownloadPdfButton';
import MedScheduleListByDay, { MedScheduleListByDayLoading } from 'components/common/mySchedule/MedScheduleListByDay';
import { useScheduledMedsQuery, useTakeMedMutation } from 'hooks/patient';
import Suspense from 'components/Suspense';
import { IScheduledMedDetails } from 'core/api/mySchedule/mySchedule.models';
import { useNavigate, generatePath } from 'react-router-dom';
import routes from 'routes';
import { myScheduleTodaysMedicationWalkThrough, WalkThrough } from 'components/patient/walkThrough';
import { ScheduledMedStatus } from 'core/models/mySchedule/mySchedule.models';

export default function TodaysMedPage() {
	const { showBoundary } = useErrorBoundary();
	const navigate = useNavigate();
	const handleDownloadPdf = () => getPdfScheduledMeds({});
	const { data: scheduledMedsData, isLoading: isLoadingScheduledMedsData } = useScheduledMedsQuery({});
	const { mutate: onLogChange, isPending } = useTakeMedMutation();
	const handleShowScheduleDetails = (scheduleDetails: IScheduledMedDetails) =>
		navigate(
			generatePath(routes.mySchedule.todaysMedDetails, {
				id: scheduleDetails.id,
			})
		);
	const isSkipped = useMemo(
		() => (scheduledMedsData?.items ?? []).filter((med) => med.status === ScheduledMedStatus.Passed).length > 0,
		[scheduledMedsData]
	);
	const isActive = useMemo(
		() => (scheduledMedsData?.items ?? []).filter((med) => med.status === ScheduledMedStatus.Active).length > 0,
		[scheduledMedsData]
	);
	const walkThroughProps = useCallback(myScheduleTodaysMedicationWalkThrough, [isActive, isSkipped]);

	return (
		<ContentCard
			title="Today’s Medication"
			actions={
				<DownloadPdfButton
					sx={{ display: { xs: 'none', sm: 'flex' } }}
					fetchPdfData={handleDownloadPdf}
					onError={showBoundary}
					variant="text"
					color="inherit"
				>
					Print Today’s Schedule
				</DownloadPdfButton>
			}
		>
			<Typography variant="body1" color="text.secondary" sx={{ display: { xs: 'none', sm: 'flex' } }}>
				Your medication schedule is based on feedback from your prescribers, pharmacists and you. Use this section to
				track if you have taken your medication. This does not replace consultation from your pharmacist or provider.
				Please call us if you have questions or concerns.
			</Typography>
			<Typography variant="body1" color="text.secondary" sx={{ display: { xs: 'none', sm: 'flex' } }}>
				<Typography variant="body1" fontWeight={700} component="b">
					Here are some helpful tips:
				</Typography>{' '}
				<Link
					href="https://www.exactcarepharmacy.com/help-with-my-medications/ask-a-pharmacist-december-2022/"
					target="_blank"
					color="text.secondary"
				>
					What should I do if I forget to take my medications?
				</Link>
			</Typography>
			<Suspense fallback={<MedScheduleListByDayLoading />} isLoading={isLoadingScheduledMedsData}>
				<MedScheduleListByDay
					data={scheduledMedsData}
					onShowScheduleDetails={handleShowScheduleDetails}
					{...{ onLogChange, isPending }}
				/>
				<WalkThrough {...walkThroughProps({ isActive, isSkipped })} />
			</Suspense>
		</ContentCard>
	);
}
