import { getRequest, postRequest, patchRequest, deleteRequest } from '../api-service';
import { endpoints } from '../endpoints';
import {
	IChangeEmailRequest,
	IChangePasswordRequest,
	IChangeAddressRequest,
	IChangeAddressResponse,
	IDeleteChangePhoneRequest,
	IGetProfileInfoResponse,
	ISendChangePhoneRequest,
	ISendChangePhoneRequestResponse,
	ISendCodeAgainRequest,
	ISendCodeAgainRequestResponse,
	ISuccessResponse,
	IVerifyPhoneNumberRequest,
	IVerifyEmailChangedRequest,
	IChangeEmailResponse,
	IMakeAPaymentResponse,
	NotificationSettings,
	NotificationSettingProps,
	UserMetaDataParams,
	UserMetaDataResponse,
	CreateUserMetaDataParams,
	UserMetaData,
	UpdateUserMetaDataParams,
	IProfileInfo,
} from './userAccount.models';

export async function getProfileInfo(): Promise<IGetProfileInfoResponse> {
	return getRequest<IGetProfileInfoResponse>(endpoints.userAccount.profileInfo, {}, {});
}

export async function updateProfileInfo(request: Partial<IProfileInfo>): Promise<ISuccessResponse> {
	const body = {
		timeZone: request.timeZone,
		medicationRemindersEnabled: request.medicationRemindersEnabled,
	};

	return postRequest<ISuccessResponse>(endpoints.userAccount.updateProfileInfo, body, {});
}

export async function addChangePhoneRequest(
	request: ISendChangePhoneRequest
): Promise<ISendChangePhoneRequestResponse> {
	const body = {
		phoneNumber: request.phoneNumber,
	};

	return postRequest<ISendChangePhoneRequestResponse>(endpoints.userAccount.changePhoneRequestEndpoint, body, {});
}

export function deleteChangePhoneRequest(request: IDeleteChangePhoneRequest): Promise<ISuccessResponse> {
	const { changePhoneRequestEndpoint } = endpoints.userAccount;
	const body = {
		id: request.id,
	};

	return deleteRequest<ISuccessResponse>(changePhoneRequestEndpoint, body, {});
}

export async function sendCodeAgainRequest(request: ISendCodeAgainRequest): Promise<ISendCodeAgainRequestResponse> {
	return patchRequest<ISendCodeAgainRequestResponse>(
		`${endpoints.userAccount.changePhoneRequestEndpoint}/${request.id}`,
		{},
		{}
	);
}

export function verifyPhoneNumberRequest(request: IVerifyPhoneNumberRequest): Promise<ISuccessResponse> {
	const { verifyPhoneNumberEndpoint } = endpoints.userAccount;
	const body = {
		phoneVerificationCode: request.phoneVerificationCode ?? '',
	};

	return postRequest<ISuccessResponse>(verifyPhoneNumberEndpoint, body, {});
}
export async function changeEmailRequest(request: IChangeEmailRequest): Promise<IChangeEmailResponse> {
	const { changeEmailEndpoint } = endpoints.userAccount;
	const body = {
		newEmail: request.newEmail,
		password: request.password,
	};
	return postRequest<IChangeEmailResponse>(changeEmailEndpoint, body, {});
}

export async function deleteAccountRequest(): Promise<ISuccessResponse> {
	const { deleteAccountEndpoint } = endpoints.userAccount;
	return postRequest<ISuccessResponse>(deleteAccountEndpoint, {}, {});
}

export async function changePasswordRequest(request: IChangePasswordRequest): Promise<ISuccessResponse> {
	const { changePasswordEndpoint } = endpoints.userAccount;
	const body = {
		password: request.password,
		newPassword: request.newPassword,
	};
	return postRequest<ISuccessResponse>(changePasswordEndpoint, body, {});
}

export async function verifyEmailChangedRequest(request: IVerifyEmailChangedRequest): Promise<ISuccessResponse> {
	const { verifyEmailChangedEndpoint } = endpoints.userAccount;
	const body = {
		hash: request.hash,
	};
	return postRequest<ISuccessResponse>(verifyEmailChangedEndpoint, body, {});
}

export async function changeAddressRequest(request: IChangeAddressRequest): Promise<IChangeAddressResponse> {
	const { changeAddressEndpoint } = endpoints.userAccount;
	const body = {
		shippingStreet: request.shippingStreet,
		shippingCity: request.shippingCity,
		shippingStateCode: request.shippingStateCode,
		shippingPostalCode: request.shippingPostalCode,
		billingStreet: request.billingStreet,
		billingCity: request.billingCity,
		billingStateCode: request.billingStateCode,
		billingPostalCode: request.billingPostalCode,
	};

	return postRequest<IChangeAddressResponse>(changeAddressEndpoint, body, {});
}

export async function makeAPaymentRequest(): Promise<IMakeAPaymentResponse> {
	const { paymentUrl: paymentUrlEndpoint } = endpoints.userAccount;

	return getRequest<IMakeAPaymentResponse>(paymentUrlEndpoint, {}, {});
}

export async function resendVerificationEmailRequest(): Promise<ISuccessResponse> {
	const { resendVerificationEmailEndpoint } = endpoints.userAccount;
	return postRequest<ISuccessResponse>(resendVerificationEmailEndpoint, {}, {});
}

export async function getNotificationSettings(): Promise<NotificationSettings> {
	return getRequest<NotificationSettings>(endpoints.userAccount.notificationSettings, {}, {});
}

export async function changeNotificationSetting(request: NotificationSettingProps): Promise<ISuccessResponse> {
	const body = {
		categoryId: request.categoryId,
		channelId: request.channelId,
		enabled: request.enabled,
	};

	return postRequest<ISuccessResponse>(endpoints.userAccount.notificationSettings, body, {});
}

export async function getUserMetaDataList(request?: UserMetaDataParams): Promise<UserMetaDataResponse> {
	const body = {
		key: request?.key || '',
	};

	return getRequest<UserMetaDataResponse>(endpoints.userAccount.userMetaDataList, body, {});
}

export async function createUserMetaData(request: CreateUserMetaDataParams): Promise<UserMetaData> {
	const body = {
		key: request.key,
		value: request.value,
	};

	return postRequest<UserMetaData>(endpoints.userAccount.createUserMetaData, body, {});
}

export async function updateUserMetaData({ id, ...restRequest }: UpdateUserMetaDataParams): Promise<UserMetaData> {
	const body = {
		key: restRequest.key,
		value: restRequest.value,
	};

	return patchRequest<UserMetaData>(endpoints.userAccount.updateUserMetaData(id.toString()), body, {});
}
