import React, { useState } from 'react';
import { DatePicker as MuiDatePicker, DatePickerProps as MuiDatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';

export interface DatePickerProps extends MuiDatePickerProps<Dayjs> {
	label?: string;
	hideDatePickerHeader?: boolean;
}

const DatePicker = React.forwardRef<HTMLInputElement, DatePickerProps>(({ sx, ...rest }, ref) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<MuiDatePicker
			open={isOpen}
			onClose={() => setIsOpen(false)}
			ref={ref}
			sx={{
				'& .MuiPickersCalendarHeader-root': {
					display: rest?.hideDatePickerHeader ? 'none' : 'flex',
				},
				...sx,
			}}
			{...rest}
			slotProps={{
				...rest?.slotProps,
				textField: {
					onClick: () => {
						if (typeof rest.onOpen === 'function') {
							rest.onOpen();
						}
						setIsOpen(true);
					},
					InputProps: {
						endAdornment: null,
					},
					...rest?.slotProps?.textField,
				},
			}}
		/>
	);
});

export default DatePicker;
