import React from 'react';
import { Link, ListItemText, Stack, Typography } from '@mui/material';

interface TextMessageEnrollmentProps {
	isEnrolled?: boolean;
	phoneNumber?: string;
}

export function TextMessageEnrollment({ isEnrolled, phoneNumber }: TextMessageEnrollmentProps) {
	return (
		<Stack gap="12px">
			<Typography variant="h2" fontWeight={700} color="text.secondary">
				Text Messages
			</Typography>
			<ListItemText
				type="inline"
				primary="Enrolled in text messages:"
				primaryTypographyProps={{ fontWeight: 700 }}
				secondary={isEnrolled ? 'Yes' : 'No'}
				secondaryTypographyProps={{ color: isEnrolled ? 'success' : 'error' }}
			/>
			<ListItemText
				type="inline"
				primary="Mobile number on file:"
				primaryTypographyProps={{ fontWeight: 700 }}
				secondary={phoneNumber || 'N/A'}
			/>

			<Typography component="p">
				Do you want text messages from ExactCare? Call 1-877-355-7225 to enroll.
				<br />
				<Link
					href="https://www.exactcarepharmacy.com/text-messages/?utm_campaign=Twilio&utm_medium=Clicked-Link&utm_source=APP"
					target="_blank"
					color="text.primary"
				>
					Learn about the benefits of enrolling in text messages.
				</Link>
			</Typography>
		</Stack>
	);
}

export default TextMessageEnrollment;
