import React, { createContext, useMemo, useContext } from 'react';
import { useAuth } from 'hooks';
import { useBadges } from 'hooks/patient/useBadges';
import { IProfileInfo } from 'core/api/userAccount/userAccount.models';

interface IUserContext
	extends Partial<
		Pick<
			IProfileInfo,
			| 'roles'
			| 'email'
			| 'emailValidated'
			| 'firstName'
			| 'medicationRemindersEnabled'
			| 'balance'
			| 'balanceUpdatedAt'
			| 'homepage'
			| 'passwordExpired'
			| 'phoneNumber'
			| 'pendingPhoneNumber'
			| 'changePhoneRequestId'
			| 'timeZone'
			| 'billingAddress'
			| 'shippingAddress'
			| 'requestedShippingAddress'
			| 'requestedBillingAddress'
			| 'changeAddress'
			| 'vialsPatient'
		>
	> {
	homeButtonBadge: number;
	medicationButtonBadge: boolean;
	unreadMessagesBadge: number;
	refillAlertsBadge: number;
	isLoading: boolean;
}

const UserContext = createContext<IUserContext | undefined>(undefined);

type UserProviderProps = {
	children: React.ReactNode;
};

export default function UserProvider({ children }: UserProviderProps) {
	const {
		isLoading: isLoadingBadges,
		homeButtonBadge,
		medicationButtonBadge,
		unreadMessagesBadge,
		refillAlertsBadge,
	} = useBadges();
	const { getAccountState } = useAuth();
	const { data, isLoading: isLoadingAccount } = getAccountState();
	const {
		roles,
		email,
		emailValidated,
		balance,
		balanceUpdatedAt,
		firstName,
		medicationRemindersEnabled,
		homepage,
		passwordExpired,
		phoneNumber,
		pendingPhoneNumber,
		changePhoneRequestId,
		timeZone,
		billingAddress,
		shippingAddress,
		requestedShippingAddress,
		requestedBillingAddress,
		changeAddress,
		vialsPatient,
	} = data ?? {};

	const contextValue = useMemo(
		() => ({
			roles,
			email,
			emailValidated,
			balance,
			balanceUpdatedAt,
			firstName,
			medicationRemindersEnabled,
			homepage,
			passwordExpired,
			billingAddress,
			shippingAddress,
			requestedShippingAddress,
			requestedBillingAddress,
			isLoading: isLoadingAccount && isLoadingBadges,
			phoneNumber,
			pendingPhoneNumber,
			changePhoneRequestId,
			timeZone,
			changeAddress,
			vialsPatient,
			medicationButtonBadge,
			homeButtonBadge,
			unreadMessagesBadge,
			refillAlertsBadge,
		}),
		[
			roles,
			email,
			emailValidated,
			balance,
			balanceUpdatedAt,
			firstName,
			medicationRemindersEnabled,
			homepage,
			passwordExpired,
			billingAddress,
			shippingAddress,
			requestedShippingAddress,
			requestedBillingAddress,
			isLoadingAccount,
			isLoadingBadges,
			phoneNumber,
			pendingPhoneNumber,
			changePhoneRequestId,
			timeZone,
			changeAddress,
			vialsPatient,
			medicationButtonBadge,
			homeButtonBadge,
			unreadMessagesBadge,
			refillAlertsBadge,
		]
	);

	return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
}

export const useUserContext = (): IUserContext => {
	const context = useContext(UserContext);
	if (!context) {
		throw new Error('useUserContext must be used within a UserProvider');
	}
	return context;
};
