import React from 'react';
import DataGrid from 'components/dataGrid';
import { Paper, PaperTitle } from 'components/dashboardLayout';
import { GridColDef } from '@mui/x-data-grid';
import { usePatientMedicationsQuery } from 'hooks/enterprise';
import RefillsStatusChip from 'components/refillsStatusChip/RefillsStatusChip';
import { Button, Avatar, Stack, Typography } from '@mui/material';
import { PillExactPackIcon } from 'components/icons/PillExactPackIcon';
import { PillBottleIcon } from 'components/icons/PillBottleIcon';
import ImageWithPreview from 'components/imageWithPreview/imageWithPreview';
import { PatientMedication } from 'core/api/enterprise/patient.models';
import DownloadPdfButton from 'components/common/inputs/DownloadPdfButton';
import { patientMedicationsPdf } from 'core/api/enterprise/patient.api';

interface MedicationListProps {
	patientId: string;
	onClickMedication: (med: PatientMedication) => void;
}

export function MedicationList({ patientId, onClickMedication }: MedicationListProps) {
	const { data, isLoading } = usePatientMedicationsQuery({
		patientId,
	});
	const { items, totalItems } = data ?? {};

	const handleDownloadPdf = () => patientMedicationsPdf({ patientId });

	const columns: GridColDef[] = [
		{ field: 'id', headerName: 'ID', sortable: false, width: 80 },
		{
			field: 'pack',
			headerName: 'Pack',
			sortable: false,
			width: 60,
			display: 'flex',
			renderCell: ({ value }) =>
				value === 'In Your ExactPack' ? (
					<PillExactPackIcon titleAccess={value} />
				) : (
					<PillBottleIcon titleAccess={value} />
				),
		},
		{
			field: 'imageUrl',
			headerName: 'Image',
			sortable: false,
			width: 60,
			display: 'flex',
			renderCell: ({ row, value: imageUrl }) => (
				<ImageWithPreview source={imageUrl} title={row.name}>
					<Avatar alt={row.name} src={imageUrl} sx={{ width: 40, height: 40 }} variant="rounded" />
				</ImageWithPreview>
			),
		},
		{
			field: 'name',
			headerName: 'Name',
			sortable: false,
			flex: 3,
			renderCell: ({ row, value }) => (
				<Button variant="text" onClick={() => onClickMedication(row)}>
					{value}
				</Button>
			),
		},
		{
			field: 'refillsStatus',
			headerName: 'Refills',
			sortable: false,
			flex: 1,
			display: 'flex',
			renderCell: ({ row, value }) => (
				<RefillsStatusChip refillsStatus={value} refillsStatusAlertLevel={row?.refillsStatusAlertLevel} />
			),
		},
		{ field: 'rxNumber', headerName: 'Rx Number', sortable: false, flex: 1 },
		{
			field: 'rxStatus',
			headerName: 'Status',
			sortable: false,
			flex: 2,
		},
		{
			field: 'prescriberName',
			headerName: 'Prescriber',
			sortable: false,
			flex: 2,
			display: 'flex',
			renderCell: ({ row, value }) => (
				<Stack direction="column" justifyContent="center">
					<Typography component="span" variant="body2">
						{value}
					</Typography>
					<Typography variant="caption" color="grey.500">
						{row?.prescriberPhone}
					</Typography>
				</Stack>
			),
		},
	];

	return (
		<Paper>
			<Stack direction="row" justifyContent="space-between" alignItems="flex-start">
				<PaperTitle>Medications ({totalItems})</PaperTitle>
				<DownloadPdfButton fetchPdfData={handleDownloadPdf}>Print Medication List</DownloadPdfButton>
			</Stack>
			<DataGrid
				autoHeight
				initialState={{
					pagination: {
						paginationModel: {
							pageSize: 25,
						},
					},
				}}
				rows={items || []}
				columns={columns}
				loading={isLoading}
				pageSizeOptions={[5, 10, 25]}
				paginationMode="client"
				disableRowSelectionOnClick
				disableColumnFilter
				columnBufferPx={8}
				getRowClassName={(params) => `row-status--${params.row.status}`}
			/>
		</Paper>
	);
}

export default MedicationList;
