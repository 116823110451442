import React, { useRef } from 'react';
import { TextField, Button, Alert, Link, Stack, Typography, InputAdornment } from '@mui/material';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { Link as RouterLink } from 'react-router-dom';
import routes from 'routes';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { Controller, useForm } from 'react-hook-form';
import { UserCredentials, MethodsMap } from 'core/api/login/login.models';
import { useAuth } from 'hooks';
import PasswordField from 'components/common/inputs/passwordField/PasswordField';
import ReCAPTCHA from 'react-google-recaptcha';

const signInSchema = Joi.object({
	email: Joi.string().required(),
	password: Joi.string().required(),
});

interface LoginFormProps {
	onLoginSuccess: (data: UserCredentials & { methods: MethodsMap }) => void;
}

export function LoginForm({ onLoginSuccess }: LoginFormProps) {
	const { get2FAMethods } = useAuth();
	const { mutate: onLogin, isPending, isError, error: apiError } = get2FAMethods;
	const recaptchaRef = useRef<ReCAPTCHA>(null);
	const { control, handleSubmit } = useForm<UserCredentials>({
		mode: 'onChange',
		resolver: joiResolver(signInSchema),
	});

	const handleLogin = async ({ email, password }: UserCredentials) => {
		if (recaptchaRef?.current) {
			const recaptchaToken = (await recaptchaRef.current.executeAsync()) || '';

			onLogin(
				{ email, password, recaptchaToken },
				{
					onSuccess: ({ methods }) => {
						onLoginSuccess({
							email,
							password,
							methods,
						});
					},
					onError: () => {
						if (recaptchaRef?.current) {
							recaptchaRef.current.reset();
						}
					},
				}
			);
		}
	};

	return (
		<>
			<Typography component="h1" fontSize="2.313rem" lineHeight="2.5rem" fontWeight="bold" color="text.secondary">
				Login to your ExactCare account
			</Typography>
			<Typography component="span" color="text.secondary" variant="body1">
				Securely access your medication and account information any time.
			</Typography>
			<Stack direction="column" gap={1} component="form" onSubmit={handleSubmit(handleLogin)}>
				<Controller
					name="email"
					control={control}
					render={({ field, fieldState: { error } }) => (
						<TextField
							{...field}
							error={!!error}
							value={field.value || ''}
							label="Email"
							autoComplete="username"
							variant="standard"
							size="small"
							margin="normal"
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<AlternateEmailIcon />
									</InputAdornment>
								),
							}}
							InputLabelProps={{
								required: false,
							}}
						/>
					)}
				/>
				<Controller
					name="password"
					control={control}
					render={({ field, fieldState: { error } }) => (
						<PasswordField
							{...field}
							error={!!error}
							value={field.value || ''}
							label="Password"
							autoComplete="current-password"
							variant="standard"
							size="small"
							margin="normal"
							disableTooltip
							fullWidth
							sx={{
								'& .MuiFormHelperText-root': {
									textAlign: 'right',
								},
							}}
							helperText={
								<Link
									component={RouterLink}
									to={routes.forgotPassword}
									variant="body1"
									fontWeight="bold"
									underline="none"
									color="text.primary"
								>
									Forgot Password
								</Link>
							}
						/>
					)}
				/>
				<ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''} size="invisible" />

				{isError && <Alert severity="error">{apiError?.message || 'Something went wrong'}</Alert>}

				<Button disabled={isPending} type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 2 }}>
					{isPending ? 'Signing In...' : 'Sign In'}
				</Button>
				<Button
					to={routes.register}
					component={RouterLink}
					fullWidth
					variant="outlined"
					color="inherit"
					sx={{ textTransform: 'none', mt: 2, color: 'text.secondary' }}
				>
					Don&apos;t have an account? Get one!
				</Button>
			</Stack>
			<Stack direction="row" gap={2} mt={4}>
				<Link href="https://anewhealthrx.com/terms-of-use/" target="_blank" color="text.secondary">
					Terms and Conditions
				</Link>
				<Link href="https://anewhealthrx.com/privacy-statement/" target="_blank" color="text.secondary">
					Privacy Policy
				</Link>
			</Stack>
		</>
	);
}

export default LoginForm;
