import React from 'react';

import { CssBaseline, createTheme, Theme } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { mainTheme } from 'themes/MainTheme';

const appTheme = createTheme(mainTheme, {
	components: {
		MuiTextField: {
			defaultProps: {
				variant: 'standard',
			},
			styleOverrides: {
				root: ({ theme }: { theme: Theme }) => ({
					'& .MuiInputLabel-standard': {
						color: theme.palette.text.secondary,
						fontSize: '1.25rem',
						lineHeight: '1.625rem',
						fontWeight: 700,
						position: 'relative',
						transform: 'none',
						[theme.breakpoints.down('sm')]: {
							fontSize: '1rem',
							lineHeight: '1.5rem',
						},

						'&.Mui-focused, &.Mui-error': {
							color: theme.palette.text.secondary,
						},
					},

					'& .MuiInputBase-root': {
						border: `2px solid ${theme.palette.divider}`,
						background: theme.palette.background.default,
						borderRadius: '20px',
						padding: '8px 16px 8px 16px',
						margin: '6px 0 0 0',

						'&:before, &:after': {
							border: '0 !important',
						},

						'&.Mui-focused': {
							borderColor: theme.palette.grey[500],
						},

						'&.Mui-error': {
							borderColor: theme.palette.error.light,
						},

						'& .MuiInputBase-input': {
							padding: 0,
							color: theme.palette.text.secondary,
						},
					},

					'& .MuiIconButton-root': {
						top: 0,
						right: 0,
					},

					'& .MuiFormHelperText-root': {
						margin: '6px 0 0 0',
					},
				}),
			},
		},
		MuiButton: {
			styleOverrides: {
				root: ({ theme }: { theme: Theme }) => ({
					borderRadius: 100,
					fontWeight: 700,
					fontSize: '1rem',
					textTransform: 'none' as const,
					...theme.typography.button1,

					'&.MuiButton-outlinedInherit': {
						boxShadow: '0px 0px 4px 0px rgba(117, 117, 117, 0.6)',
						borderColor: 'transparent',
					},
				}),
			},
		},
		MuiSelect: {
			styleOverrides: {
				root: ({ theme }: { theme: Theme }) => ({
					border: `2px solid ${theme.palette.divider}`,
					background: theme.palette.background.default,
					borderRadius: '20px',
					margin: '6px 0 0 0',

					'&:before, &:after': {
						border: '0 !important',
					},

					'&.Mui-focused': {
						borderColor: theme.palette.grey[500],
					},

					'&.Mui-error': {
						borderColor: theme.palette.error.light,
					},

					'& .MuiInputBase-input': {
						padding: '8px 16px 8px 16px',
						color: theme.palette.text.secondary,
					},

					'& .MuiInputLabel-standard': {
						color: theme.palette.text.secondary,
						fontSize: '1.25rem',
						lineHeight: '1.625rem',
						fontWeight: 700,
						position: 'relative',
						transform: 'none',
						[theme.breakpoints.down('sm')]: {
							fontSize: '1rem',
							lineHeight: '1.5rem',
						},

						'&.Mui-focused, &.Mui-error': {
							color: theme.palette.text.secondary,
						},
					},

					'& .MuiIconButton-root': {
						top: 0,
						right: 0,
					},

					'& .MuiFormHelperText-root': {
						margin: '6px 0 0 0',
					},
				}),
			},
		},
	},
});

export function AppThemeProvider({ children }: { children: React.ReactNode }) {
	return (
		<MuiThemeProvider theme={appTheme}>
			<CssBaseline />
			{children}
		</MuiThemeProvider>
	);
}
