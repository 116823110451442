import React from 'react';
import { Avatar, Typography } from '@mui/material';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import routes from 'routes';
import eventService from 'services/eventService';
import Suspense from 'components/Suspense';
import { RefillAlertsWidget } from 'components/inbox';
import InfoCardWidget from 'components/InfoCardWidget';
import DownloadPdfButtonWidget from 'components/patient/common/DownloadPdfWidget';
import ContentCard from '../../../card/ContentCard';
import SidebarList from '../../../sidebarList/SidebarList';
import SidebarListItem from '../../../sidebarList/SidebarListItem';
import { IMedsGrouped } from '../../../../core/models/medications/medications.models';
import { getPdfMedications } from '../../../../core/api/medications/medications.api';

interface IMedicationSidebarProps {
	medicationsIsLoading: boolean;
	medsGroupedByPacks: IMedsGrouped | null;
	anyOrderReceived: boolean;
	activeSfid?: string;
	onDownloadPdfError?: (error: string) => void;
}

export function MedicationSidebar({
	medicationsIsLoading,
	medsGroupedByPacks,
	anyOrderReceived,
	activeSfid,
	onDownloadPdfError,
}: IMedicationSidebarProps) {
	const handleDownloadPdf = () => {
		eventService.emitEvent({ category: 'button', action: 'medicationListDownloadPdf' });

		return getPdfMedications();
	};

	return (
		<ContentCard title="Medication">
			<Suspense isLoading={medicationsIsLoading}>
				{!medsGroupedByPacks && anyOrderReceived && (
					<Typography variant="body1" color="text.secondary">
						Please call us at 1-877-355-7225. We need you to call us before we can send your next order.
					</Typography>
				)}
				{!medsGroupedByPacks && !anyOrderReceived && (
					<Typography variant="body1" color="text.secondary">
						We are working on getting your prescriptions transferred to us. We will add your medications as we get them
						from your doctor. Your entire medication list will be here after your first monthly order has been shipped.
					</Typography>
				)}
				<SidebarList>
					<RefillAlertsWidget />
					{medsGroupedByPacks && (
						<DownloadPdfButtonWidget
							title="Print Medication List"
							fetchPdfData={handleDownloadPdf}
							onError={onDownloadPdfError}
						/>
					)}
				</SidebarList>
				{Object.entries(medsGroupedByPacks || {}).map(([title, medications]) => (
					<SidebarList key={title}>
						<Typography component="h2" variant="h1" fontWeight="bold" color="text.secondary" sx={{ pt: '5px' }}>
							{title}
						</Typography>
						{medications.map(({ sfid, name, imageUrl, refillAlert, onboardingAlerts }) => (
							<SidebarListItem
								key={sfid}
								to={generatePath(routes.medication, {
									sfid,
								})}
								state={{ showSidebarNavigation: false }}
								component={RouterLink}
								className={activeSfid && activeSfid === sfid ? 'active' : ''}
							>
								<InfoCardWidget
									icon={
										imageUrl ? (
											<Avatar src={imageUrl} alt={name || ''} variant="rounded" sx={{ width: 36, height: 36 }} />
										) : undefined
									}
									title={name || ''}
									alert={!!refillAlert || !!onboardingAlerts?.length}
								/>
							</SidebarListItem>
						))}
					</SidebarList>
				))}
			</Suspense>
		</ContentCard>
	);
}

export default MedicationSidebar;
