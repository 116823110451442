import React from 'react';
import { Grid } from '@mui/material';
import { PatientMedSyncList } from 'components/admin';
import withPageContext from 'hoc/withPageContext';
import { useParams } from 'react-router-dom';

function PatientsMedSyncPage() {
	const { patientId } = useParams();

	return (
		<Grid item xs={12}>
			<PatientMedSyncList userId={Number(patientId)} />
		</Grid>
	);
}

export default withPageContext(PatientsMedSyncPage);
