import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Stack, Typography } from '@mui/material';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { Controller, useForm } from 'react-hook-form';
import { UserCredentials } from 'core/api/login/login.models';
import TwoFAField from 'components/common/inputs/TwoFAField';
import useAuth from 'hooks/useAuth';

const twoFASchema = Joi.object({
	authCode: Joi.string().required(),
});

interface TwoFACodeFormProps extends UserCredentials {
	methodId: number;
	methodName: string;
	onResendCode: () => void;
}

export function TwoFACodeForm({ email, password, methodName, onResendCode }: TwoFACodeFormProps) {
	const {
		watch,
		control,
		handleSubmit,
		formState: { isValid },
	} = useForm<{ authCode: string }>({
		mode: 'onChange',
		resolver: joiResolver(twoFASchema),
	});
	const { verifyAuthenticationCode } = useAuth();
	const { mutate: onVerifyCode, isPending, isError, error: apiError } = verifyAuthenticationCode;
	const chosenMethodTitle = methodName.split(' ').slice(3, 4)[0] === 'SMS' ? 'Phone' : 'Email';
	const chosenMethodText = methodName.split(' ').slice(2).join(' ');
	const watchAuthCode = watch('authCode');
	const [showResubmitBtn, setShowResubmitBtn] = useState(false);

	const handleVerify = useCallback(
		({ authCode }: { authCode: string }) => {
			onVerifyCode(
				{
					email,
					password,
					authCode,
				},
				{
					onError: () => {
						setShowResubmitBtn(true);
					},
				}
			);
		},
		[email, password, onVerifyCode, setShowResubmitBtn]
	);
	const handleCodeChange = useCallback(
		(authCode: string) => {
			if (authCode && authCode.length === 6 && !isError && !isPending) {
				handleSubmit(handleVerify)();
			}
		},
		[isError, isPending]
	);

	useEffect(() => {
		handleCodeChange(watchAuthCode);
	}, [watchAuthCode]);

	return (
		<>
			<Typography component="h1" fontSize="2.313rem" lineHeight="2.5rem" fontWeight="bold" color="text.secondary">
				Check your {chosenMethodTitle}!
			</Typography>
			<Typography component="span" color="text.secondary">
				We sent your six-digit code {chosenMethodText} This code will expire in 5 minutes. Enter it here to access your
				account:
			</Typography>
			<Stack direction="column" gap="12px" component="form" onSubmit={handleSubmit(handleVerify)}>
				{isError && <Alert severity="error">{apiError?.message || 'Something went wrong'}</Alert>}
				<Controller
					name="authCode"
					control={control}
					render={({ field: { ref, ...restField }, fieldState: { error } }) => (
						<TwoFAField {...restField} error={!!error} disabled={isPending} variant="standard" />
					)}
				/>
				{showResubmitBtn && (
					<Button type="submit" fullWidth variant="contained" color="primary" disabled={isPending || !isValid}>
						Resubmit
					</Button>
				)}
			</Stack>
			<Stack alignItems="flex-start" justifyContent="center">
				<Typography color="text.secondary" fontWeight={700}>
					Didn‘t receive the code?
				</Typography>
				<Button variant="text" color="inherit" onClick={onResendCode}>
					Send a new code
				</Button>
			</Stack>
		</>
	);
}

export default TwoFACodeForm;
