import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const MedsItemContent = styled(Stack)(({ theme }) => ({
	'& .MuiList-root': {
		paddingBottom: 0,
	},

	'& .MuiListItem-root': {
		padding: 0,
	},

	'& .MuiListItemText-root': {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-start',
		gap: 3,
		margin: 0,
	},

	'& .MuiListItemText-primary': {
		fontStyle: 'normal',
		fontWeight: '700',
		letterSpacing: 0.5,
	},
	'& .MuiListItemText-secondary': {
		fontStyle: 'normal',
		fontWeight: '400',
		letterSpacing: 0.5,
		color: theme.palette.text.secondary,
	},
}));
