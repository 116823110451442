import breakpoints from './breakpoints';

export default {
	fontFamily: 'Roboto, sans-serif',
	fontWeightLight: 300,
	fontWeightRegular: 400,
	fontWeightMedium: 500,
	fontWeightBold: 700,
	h1: {
		fontSize: '1.5rem',
		lineHeight: '1.875rem',
		[breakpoints.down('sm')]: {
			fontSize: '1.563rem',
			lineHeight: '1.875rem',
		},
	},
	h2: {
		fontSize: '1.25rem',
		lineHeight: '1.625rem',
		[breakpoints.down('sm')]: {
			fontSize: '1.125rem',
			lineHeight: '1.375rem',
		},
	},
	h3: {
		fontSize: '1.5rem',
		lineHeight: '1.875rem',
		[breakpoints.down('sm')]: {
			fontSize: '1.125rem',
			lineHeight: '1.375rem',
		},
	},
	h4: {
		fontSize: 26,
		lineHeight: '40px',
	},
	// h5: undefined,
	subtitle1: {
		fontSize: '1.125rem',
		lineHeight: '1.375rem',
		[breakpoints.down('sm')]: {
			fontSize: '0.875rem',
			lineHeight: '1rem',
		},
	},
	// subtitle2: undefined,
	body1: {
		fontSize: '1rem',
		lineHeight: '1.5rem',
		[breakpoints.down('sm')]: {
			fontSize: '0.875rem',
			lineHeight: '1rem',
		},
	},
	body2: {
		fontSize: '1.125rem',
		lineHeight: '1.625rem',
		[breakpoints.down('sm')]: {
			fontSize: '1rem',
			lineHeight: '1.5rem',
		},
	},
	caption: {
		fontSize: '0.875rem',
		lineHeight: '0.875rem',
	},
	button1: {
		fontSize: '1rem',
		lineHeight: '1.5rem',
	},
};
