import React from 'react';
import { Card, Typography, Button, Stack, Theme } from '@mui/material';
import { TooltipRenderProps } from 'react-joyride';

export function WalkThroughTooltip({ index, step, isLastStep, size, primaryProps }: TooltipRenderProps) {
	let primaryButtonName = 'Next';

	if (isLastStep) {
		primaryButtonName = 'Done';
	} else if (step.placement === 'center' && index === 0) {
		primaryButtonName = 'Start';
	}

	return (
		<Card
			component={Stack}
			sx={{
				maxWidth: '324px',
				...step.styles.tooltip,
				boxShadow: '0px 0px 60px 0px rgba(71, 10, 104, 0.35)',
				padding: '24px',
				gap: '12px',
				borderRadius: '10px',
				background: (theme: Theme) => theme.palette.background.default,
			}}
		>
			<Typography variant="body1" component={Stack} fontWeight={500} color="text.secondary">
				{step.title}
			</Typography>
			<Typography variant="body1" component={Stack}>
				{step.content}
			</Typography>
			<Stack direction="row" gap={2} justifyContent="space-between" alignItems="center" pt="12px">
				<Button color="primary" variant="contained" size="small" sx={{ flex: 1 }} {...primaryProps}>
					{primaryButtonName}
				</Button>
				{step.showProgress && (
					<Typography variant="caption" fontWeight={700} color="text.secondary">
						{index + 1} of {size}
					</Typography>
				)}
			</Stack>
		</Card>
	);
}

export default WalkThroughTooltip;
