import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { deleteUserMetaData } from 'core/api/admin/patients.api';
import { UserMetaDataParams } from 'core/api/admin/patients.models';
import { adminPatientQueries } from 'queries/admin';

export function useUserMetaData() {
	const queryClient = useQueryClient();

	const userMetaDataListQuery = (props: UserMetaDataParams) => {
		return useQuery(adminPatientQueries.userMetaDataList(props));
	};

	const deleteUserMetaDataMutation = useMutation({
		mutationFn: deleteUserMetaData,
		onSuccess: (data, variables) => {
			queryClient.invalidateQueries({
				queryKey: ['admin', 'patient', 'view', variables.userId, 'userMetaData'],
			});
		},
	});

	return {
		userMetaDataListQuery,
		deleteUserMetaDataMutation,
	};
}

export default useUserMetaData;
