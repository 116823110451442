import React, { useCallback, useMemo, useState } from 'react';
import { CreateMedsProcessingJobsParams, PatientDetails } from 'core/api/admin/patients.models';
import { useMedsProcessingJobsCreateMutation } from 'hooks/admin';
import { Alert, Button, Stack, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { DateField } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { DateRange, PickerModal } from 'mui-daterange-picker-plus';

const schema = Joi.object({
	from: Joi.string().required(),
	to: Joi.string().required(),
});

interface CreateMedsProcessingJobsForm extends Exclude<CreateMedsProcessingJobsParams, 'patientId'> {}

interface PatientMedSyncSaveProps extends Pick<PatientDetails, 'userId'> {
	onSaveSuccess: () => void;
}

export function PatientMedSyncSave({ onSaveSuccess, userId }: PatientMedSyncSaveProps) {
	const dateRanges = useMemo(
		() => [
			{
				label: 'Next 7 days',
				startDate: dayjs().toDate(),
				endDate: dayjs().add(7, 'day').toDate(),
			},
			{
				label: 'Next week',
				startDate: dayjs().startOf('week').add(1, 'week').toDate(),
				endDate: dayjs().startOf('week').add(1, 'week').endOf('week').toDate(),
			},
			{
				label: 'Next 30 days',
				startDate: dayjs().toDate(),
				endDate: dayjs().add(30, 'day').toDate(),
			},
			{
				label: 'Next month',
				startDate: dayjs().add(1, 'month').startOf('month').toDate(),
				endDate: dayjs().add(1, 'month').endOf('month').toDate(),
			},
			{
				label: 'Next year',
				startDate: dayjs().add(1, 'year').startOf('year').toDate(),
				endDate: dayjs().add(1, 'year').endOf('year').toDate(),
			},
		],
		[]
	);
	const { mutate: patientMedSyncMutate, isPending, error } = useMedsProcessingJobsCreateMutation();
	const {
		control,
		handleSubmit,
		setValue,
		getValues,
		formState: { isValid },
	} = useForm<CreateMedsProcessingJobsForm>({
		mode: 'onChange',
		resolver: joiResolver(schema),
		defaultValues: {
			from: dateRanges[2].startDate.toISOString(),
			to: dateRanges[2].endDate.toISOString(),
		},
	});
	const handlePatientMedSyncSubmit = (data: CreateMedsProcessingJobsForm) => {
		patientMedSyncMutate({ ...data, patientId: userId.toString() }, { onSuccess: onSaveSuccess });
	};

	const [anchorPickerModal, setAnchorPickerModal] = useState<HTMLDivElement | null>(null);

	const handleClosePickerModal = useCallback(() => {
		setAnchorPickerModal(null);
	}, []);

	const handleSetDateRangeOnSubmit = useCallback(
		(dateRange: DateRange) => {
			if (dateRange?.startDate && dateRange?.endDate) {
				setValue('from', dayjs(dateRange.startDate).format('YYYY-MM-DD'));
				setValue('to', dayjs(dateRange.endDate).format('YYYY-MM-DD'));
			}

			handleClosePickerModal();
		},
		[setValue]
	);

	const handleOpenPickerModal = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
		setAnchorPickerModal(event.currentTarget);
	}, []);

	const isOpenPickerModal = Boolean(anchorPickerModal);

	return (
		<Stack gap={2} component="form" onSubmit={handleSubmit(handlePatientMedSyncSubmit)}>
			<Stack direction="row" gap={2}>
				<Stack flex={1} gap={1}>
					<Typography component="span" variant="input2" fontWeight={700}>
						From
					</Typography>
					<Controller
						name="from"
						control={control}
						render={({ field: { onChange, value, ...fieldProps } }) => (
							<DateField
								onClick={handleOpenPickerModal}
								value={value ? dayjs(value) : null}
								onChange={(newValue) => {
									onChange(newValue ? dayjs(newValue).format('YYYY-MM-DD') : null);
								}}
								slotProps={{ textField: { size: 'small', variant: 'outlined' } }}
								{...fieldProps}
							/>
						)}
					/>
				</Stack>
				<Stack flex={1} gap={1}>
					<Typography component="span" variant="input2" fontWeight={700}>
						To
					</Typography>
					<Controller
						name="to"
						control={control}
						render={({ field: { onChange, value, ...fieldProps } }) => (
							<DateField
								onClick={handleOpenPickerModal}
								value={value ? dayjs(value) : null}
								onChange={(newValue) => {
									onChange(newValue ? dayjs(newValue).format('YYYY-MM-DD') : null);
								}}
								slotProps={{ textField: { size: 'small', variant: 'outlined' } }}
								{...fieldProps}
							/>
						)}
					/>
				</Stack>
			</Stack>
			{error?.message && <Alert severity="error">{error.message}</Alert>}
			<Button disabled={!isValid && isPending} variant="outlined" color="primary" fullWidth type="submit">
				{isPending ? 'Loading...' : 'Save'}
			</Button>

			<PickerModal
				definedRanges={dateRanges}
				initialDateRange={{
					startDate: dayjs(getValues('from')).toDate(),
					endDate: dayjs(getValues('to')).toDate(),
				}}
				customProps={{
					onSubmit: handleSetDateRangeOnSubmit,
					onCloseCallback: handleClosePickerModal,
				}}
				modalProps={{
					open: isOpenPickerModal,
					onClose: handleClosePickerModal,
					anchorEl: anchorPickerModal,
					slotProps: {
						paper: {
							sx: {
								'& .MuiButton-root.MuiButton-text': {
									marginRight: '15px',
								},
							},
						},
					},
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'left',
					},
				}}
			/>
		</Stack>
	);
}

export default PatientMedSyncSave;
