import React, { useState, forwardRef, useImperativeHandle, useMemo } from 'react';
import { MonthCalendar } from '@mui/x-date-pickers/MonthCalendar';
import dayjs, { Dayjs } from 'dayjs';
import { Paper, Popper, Stack, ClickAwayListener } from '@mui/material';
import { DateField, DateFieldProps } from '@mui/x-date-pickers';

interface MonthPickerProps extends Omit<DateFieldProps<Dayjs>, 'value' | 'onChange'> {
	value?: number | null;
	onChange?: (newValue: number | null) => void;
}

export const MonthPicker = forwardRef(({ value, onChange, ...restProps }: MonthPickerProps, ref) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [internalValue, setInternalValue] = useState<number | null>(null);
	const [open, setOpen] = useState(false);

	const isControlled = value !== undefined && onChange !== undefined;
	const selectedMonthObj = useMemo(() => {
		const selectedMonth = isControlled ? value : internalValue;

		if (selectedMonth) {
			return dayjs('1964-01-01').month(selectedMonth - 1);
		}

		return null;
	}, [isControlled, value, internalValue]);

	const handleOpenPopper = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
		setOpen(true);
	};

	const handleClosePopper = () => {
		setOpen(false);
	};

	const handleMonthChange = (newMonth: Dayjs | null) => {
		const month = Number(newMonth?.format('MM'));

		if (isControlled && onChange) {
			onChange(month);
		} else {
			setInternalValue(month);
		}
	};

	const handleMonthPick = (newMonth: Dayjs | null) => {
		handleMonthChange(newMonth);
		handleClosePopper();
	};

	useImperativeHandle(ref, () => ({
		focus: () => setOpen(true),
		openPicker: () => setOpen(true),
		closePicker: () => setOpen(false),
	}));

	return (
		<ClickAwayListener onClickAway={handleClosePopper}>
			<Stack
				onBlur={(event) => {
					if (!event.currentTarget?.contains(event.relatedTarget)) {
						handleClosePopper();
					}
				}}
			>
				<DateField
					value={selectedMonthObj}
					format="MM"
					onClick={handleOpenPopper}
					onChange={handleMonthChange}
					{...restProps}
				/>
				<Popper
					disablePortal
					open={open}
					anchorEl={anchorEl}
					placement="bottom-start"
					sx={{
						zIndex: (theme) => theme.zIndex.modal,
					}}
				>
					<Paper>
						<MonthCalendar value={selectedMonthObj} onChange={handleMonthPick} />
					</Paper>
				</Popper>
			</Stack>
		</ClickAwayListener>
	);
});

export default MonthPicker;
