import { WalkThroughScreen, WalkThroughScreenKeys } from 'components/patient/walkThrough/WalkThrough';

export const myScheduleTodaysMedDetailsWalkThrough: WalkThroughScreen = {
	screenKey: WalkThroughScreenKeys.MY_SCHEDULE_TODAY_MEDS_SCHEDULED_TIME,
	steps: [
		{
			target: '.EcaMedTableRow-root',
			title: 'Toggle the buttons to track which medication you have taken for the day.',
			content: 'Green means taken. Gray means skipped.',
			disableBeacon: true,
			disableOverlayClose: false,
		},
	],
};
