import { useQuery } from '@tanstack/react-query';
import { getInboxNotifications, InboxNotification, InboxNotificationListParams } from 'core/api/inboxNotification';
import normalizeHydraCollection from 'utils/normalizeHydraCollection';

export function useInboxNotificationsQuery({ page, ...restRequest }: InboxNotificationListParams) {
	const queryInfo = useQuery({
		queryKey: ['inbox', 'list', restRequest, page],
		queryFn: () => getInboxNotifications({ page, ...restRequest }),
		select: normalizeHydraCollection<InboxNotification>,
		refetchInterval: 30000,
		refetchIntervalInBackground: true,
		enabled: true,
		refetchOnWindowFocus: true,
	});

	return {
		isLoading: queryInfo.isLoading,
		items: queryInfo.data?.items || [],
		totalItems: queryInfo.data?.totalItems || 0,
		pagination: queryInfo.data?.pagination || {},
	};
}

export default useInboxNotificationsQuery;
