import React, { useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Typography, Link, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import routes from 'routes';
import { NotificationChannel, NotificationSettingProps } from 'core/api/userAccount/userAccount.models';
import { useDisclosure, useNotificationSettingMutation, useNotificationSettingsQuery } from 'hooks';
import {
	NotificationSettingRow,
	NotificationSettingRowLoading,
} from 'components/patient/account/NotificationSettingRow';
import { ContentCard } from 'components/card';
import TextMessageEnrollment from 'components/patient/account/TextMessageEnrollment';
import { useUserContext } from 'contexts/UserProvider';
import Dialog from 'components/dialog';
import { ResendVerificationEmail } from 'components/patient/account';

export default function NotificationsPreferencesPage() {
	const { emailValidated } = useUserContext();
	const { data: notificationSettings, isLoading: isLoadingNotificationSettings } = useNotificationSettingsQuery();
	const { mutate: notificationSettingMutate, isPending: isPendingUpdateNotification } =
		useNotificationSettingMutation();
	const isNotificationEnabled = useCallback(
		(categoryId: NotificationChannel, channelId: number) => {
			if (channelId === NotificationChannel.EMAIL && !emailValidated) {
				return false;
			}

			return (
				notificationSettings?.values.find((value) => value.categoryId === categoryId && value.channelId === channelId)
					?.enabled ?? false
			);
		},
		[notificationSettings, emailValidated]
	);
	const { isOpen: openResendVerificationEmailDialog, onToggle: toggleResendVerificationEmailDialog } = useDisclosure();
	const resendVerificationEmailDialog = (
		<Dialog
			scroll="paper"
			fullWidth
			maxWidth="xs"
			open={openResendVerificationEmailDialog}
			onClose={toggleResendVerificationEmailDialog}
		>
			<ResendVerificationEmail />
		</Dialog>
	);

	const handleChangeNotificationSetting = (props: NotificationSettingProps) => {
		if (props.channelId === NotificationChannel.EMAIL && !emailValidated) {
			toggleResendVerificationEmailDialog();
		} else {
			notificationSettingMutate(props);
		}
	};

	return (
		<ContentCard title="Notifications Preferences" sx={{ '& .MuiCardContent-root': { p: 0 } }}>
			<Typography variant="body1" color="text.secondary" sx={{ display: { xs: 'block', sm: 'none' }, p: '12px' }}>
				Choose how you want to receive notifications when you have a new message in your inbox.
			</Typography>
			<Table
				sx={{
					'& .MuiTableCell-root': {
						borderBottom: 0,

						'&:first-of-type': {
							paddingLeft: { xs: '12px', sm: 0 },
						},
						'&:last-of-type': {
							paddingRight: { xs: '12px', sm: 0 },
						},
					},
					'& .MuiTableCell-head': {
						color: 'text.secondary',
						fontWeight: 700,
					},
					'& .MuiTableCell-body': (theme) => ({
						borderTop: '1px solid',
						borderColor: 'divider',

						[theme.breakpoints.down('sm')]: {
							border: 0,
							backgroundColor: theme.palette.background.default,
							marginBottom: '20px',
							display: 'flex',
							gap: '12px',
							flexDirection: 'column',
						},
					}),
				}}
			>
				<TableHead sx={{ display: { xs: 'none', sm: 'table-header-group' } }}>
					<TableRow>
						<TableCell align="left">
							Choose how you want to receive notifications when you have a new message in your inbox.
						</TableCell>
						<TableCell align="center">Email Message</TableCell>
						<TableCell align="center">App Notification</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{isLoadingNotificationSettings && <NotificationSettingRowLoading />}
					{!isLoadingNotificationSettings &&
						notificationSettings?.categories.map(({ id: categoryId, title, description }) => (
							<NotificationSettingRow
								key={categoryId}
								emailEnabled={isNotificationEnabled(categoryId, NotificationChannel.EMAIL)}
								pushEnabled={isNotificationEnabled(categoryId, NotificationChannel.PUSH)}
								onChange={handleChangeNotificationSetting}
								isPending={isPendingUpdateNotification}
								{...{ title, description, categoryId }}
							/>
						))}
					<TableRow>
						<TableCell align="left" colSpan={3}>
							<TextMessageEnrollment
								isEnrolled={notificationSettings?.enrolledInSMS}
								phoneNumber={notificationSettings?.contactMobilePhone}
							/>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell align="left" colSpan={3}>
							<Typography variant="h2" fontWeight={700} color="text.secondary">
								Medication Reminders
							</Typography>
							<Typography component="p" color="text.secondary">
								<Link to={routes.mySchedule.manageMedicationReminders} component={RouterLink} color="text.secondary">
									Click here
								</Link>{' '}
								to manage reminders sent to your phone when it&apos;s time to take your medications.
							</Typography>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
			{resendVerificationEmailDialog}
		</ContentCard>
	);
}
