import React from 'react';
import { Outlet } from 'react-router-dom';
import GoogleAnalytics from 'components/GoogleAnalytics';
import ErrorBoundaryProvider from 'contexts/ErrorBoundaryProvider';
import PageProvider from 'contexts/PageProvider';
import AutoLogout from 'components/AutoLogout';
import Hotjar from 'components/Hotjar';

function App() {
	return (
		<ErrorBoundaryProvider>
			<PageProvider>
				<AutoLogout>
					<GoogleAnalytics />
					<Outlet />
					<Hotjar />
				</AutoLogout>
			</PageProvider>
		</ErrorBoundaryProvider>
	);
}

export default App;
