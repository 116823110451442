import React, { useMemo } from 'react';
import InfoCardWidget from 'components/InfoCardWidget';
import { PaletteOptions, Stack } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { CalendarWeek, CalendarWeekDay, CalendarWeekLegend } from 'components/calendarWeek';
import { SidebarListItem } from 'components/sidebarList';
import useWeekDays, { IDayDetails } from 'hooks/useWeekDays';
import { useUserContext } from 'contexts/UserProvider';
import routes from 'routes';

type MedsLoggedThisWeek = IDayDetails & { color?: keyof PaletteOptions };

export function MedicationLoggedThisWeekWidget() {
	const { homepage } = useUserContext();
	const medsThisWeek = homepage?.medsThisWeek || [];
	const weekDays = useWeekDays();

	const medsLoggedThisWeek: MedsLoggedThisWeek[] = useMemo(() => {
		return weekDays.map((day) => {
			const medThisWeek = medsThisWeek.find((med) => med.day === day.day.format('YYYY-MM-DD'));
			let color: keyof PaletteOptions | undefined;

			if (medThisWeek?.status === 'logged') {
				color = 'success';
			} else if (medThisWeek?.status === 'incomplete') {
				color = 'warning';
			}

			return {
				...day,
				color,
			};
		});
	}, [weekDays, medsThisWeek]);

	return (
		<SidebarListItem
			to={routes.mySchedule.reviewMyHistory.calendar}
			component={RouterLink}
			className="EcaListItem-medicationLoggedThisWeek"
		>
			<InfoCardWidget title="Medication Logged This Week" disableArrow>
				<Stack direction="column" gap="12px" py="12px">
					<CalendarWeek>
						{medsLoggedThisWeek.map(({ day, today, color }) => (
							<CalendarWeekDay
								key={day.format('ddd')}
								day={day.date()}
								dayLabel={day.format('ddd')}
								showWeekDayLabel
								{...{ today, color }}
							/>
						))}
					</CalendarWeek>
					<CalendarWeekLegend
						items={[
							{ label: 'Logged', color: 'success' },
							{ label: 'Incomplete', color: 'warning' },
						]}
					/>
				</Stack>
			</InfoCardWidget>
		</SidebarListItem>
	);
}

export default MedicationLoggedThisWeekWidget;
