import React from 'react';
import CircularLoading from 'components/CircularLoading';

interface SuspenseProps {
	isLoading?: boolean; // Controlled Suspense
	fallback?: React.ReactNode;
	children: React.ReactNode;
}

export default function Suspense({ isLoading = false, fallback = <CircularLoading />, children }: SuspenseProps) {
	if (isLoading) {
		return fallback;
	}

	return <React.Suspense fallback={fallback}>{children}</React.Suspense>;
}
