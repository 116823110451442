import { Theme } from '@mui/material';

export default {
	styleOverrides: {
		root: ({ theme }: { theme: Theme }) => ({
			color: theme.palette.text.secondary,
		}),
		icon: {
			padding: '8px 0',
		},
	},
};
