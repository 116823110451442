import {
	muiButtonStyle as MuiButton,
	muiTooltipStyle as MuiTooltip,
	muiAccordionStyle as MuiAccordion,
	muiDialogStyle as MuiDialog,
	muiListItemTextStyle as MuiListItemText,
	muiSwitchStyle as MuiSwitch,
	muiAlertStyle as MuiAlert,
} from './componentStyles';

export default {
	MuiButton,
	MuiTooltip,
	MuiAccordion,
	MuiDialog,
	MuiDialogContent: {
		styleOverrides: {
			root: {
				padding: '0 24px 32px 24px',
			},
		},
	},
	MuiListItemText,
	MuiSwitch,
	MuiAlert,
};
