import React from 'react';
import { Stack, TableCell, TableRow, Theme, Typography, useMediaQuery } from '@mui/material';
import Switch from 'components/common/inputs/switch/Switch';
import { NotificationChannel, NotificationSettingProps } from 'core/api/userAccount/userAccount.models';

interface NotificationSettingRowProps {
	title: string;
	description: string;
	categoryId: number;
	emailEnabled: boolean;
	pushEnabled: boolean;
	isPending?: boolean;
	onChange: (props: NotificationSettingProps) => void;
}

export function NotificationSettingRow({
	title,
	description,
	categoryId,
	emailEnabled,
	pushEnabled,
	isPending = false,
	onChange,
}: NotificationSettingRowProps) {
	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		onChange({ categoryId, channelId: NotificationChannel.EMAIL, enabled: event.target.checked });
	};
	const handlePushChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		onChange({ categoryId, channelId: NotificationChannel.PUSH, enabled: event.target.checked });
	};
	const upSmallScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));

	if (!upSmallScreen) {
		return (
			<TableRow>
				<TableCell align="left" colSpan={3}>
					<Stack gap="12px">
						<Typography variant="h2" fontWeight={700} color="text.secondary">
							{title}
						</Typography>
						<Typography component="p">{description}</Typography>
						<Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
							<Typography fontWeight={700} color="text.secondary">
								Email
							</Typography>{' '}
							<Switch
								labelOn="On"
								labelOff="Off"
								color="success"
								disabled={isPending}
								onChange={handleEmailChange}
								checked={emailEnabled}
							/>
						</Stack>
						<Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
							<Typography fontWeight={700} color="text.secondary">
								App Notification
							</Typography>
							<Switch
								labelOn="On"
								labelOff="Off"
								color="success"
								disabled={isPending}
								onChange={handlePushChange}
								checked={pushEnabled}
							/>
						</Stack>
					</Stack>
				</TableCell>
			</TableRow>
		);
	}

	return (
		<TableRow>
			<TableCell align="left">
				<Typography variant="h2" fontWeight={700} color="text.secondary">
					{title}
				</Typography>
				<Typography component="p">{description}</Typography>
			</TableCell>
			<TableCell align="center">
				<Switch
					labelOn="On"
					labelOff="Off"
					color="success"
					disabled={isPending}
					onChange={handleEmailChange}
					checked={emailEnabled}
				/>
			</TableCell>
			<TableCell align="center">
				<Switch
					labelOn="On"
					labelOff="Off"
					color="success"
					disabled={isPending}
					onChange={handlePushChange}
					checked={pushEnabled}
				/>
			</TableCell>
		</TableRow>
	);
}

export default NotificationSettingRow;
