import React from 'react';
import { Button, Box, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import { MedsItem, MedsItemContent, MedsItemImage } from 'components/medsList';
import ImageWithPreview from 'components/imageWithPreview/imageWithPreview';
import { IAsNeededMedication } from 'core/api/mySchedule/mySchedule.models';

interface AsNeededMedListItemProps extends IAsNeededMedication {
	showUpdateMessage: boolean;
	onRecordTaken: (asNeededMedication: IAsNeededMedication) => void;
	onViewUsageHistory: (asNeededMedication: IAsNeededMedication) => void;
	onViewDirections: (asNeededMedication: IAsNeededMedication) => void;
}

export function AsNeededMedListItem({
	onRecordTaken,
	onViewUsageHistory,
	onViewDirections,
	showUpdateMessage,
	...asNeededMedication
}: AsNeededMedListItemProps) {
	const { scheduledMedId, name, imageUrl, lastTakenAt, howToUse } = asNeededMedication;

	return (
		<MedsItem
			key={scheduledMedId}
			sx={{
				border: '1px solid',
				borderColor: (theme) => theme.palette.grey[400],
				padding: '24px',
				gap: '10px',
				borderRadius: '10px',
			}}
		>
			<MedsItemImage>
				{imageUrl && (
					<ImageWithPreview source={imageUrl} title={name || ''}>
						<Box component="img" src={imageUrl} alt={name || ''} />
					</ImageWithPreview>
				)}
			</MedsItemImage>
			<MedsItemContent spacing={1}>
				<Typography variant="h4" color="text.secondary">
					{name}
				</Typography>
				<Stack gap={2} direction="row">
					<Button onClick={() => onRecordTaken(asNeededMedication)} size="small" variant="contained" color="primary">
						Take
					</Button>
					{howToUse && (
						<Button
							onClick={() => onViewDirections(asNeededMedication)}
							size="small"
							variant="outlined"
							color="inherit"
						>
							View Directions
						</Button>
					)}
				</Stack>
				{showUpdateMessage && (
					<Typography color="success.main" fontWeight={700}>
						As-Needed Medication Dose Logged.
					</Typography>
				)}
				<List disablePadding>
					<ListItem sx={{ margin: '10px 0' }}>
						<ListItemText
							primary="Last Reported Dose:"
							primaryTypographyProps={{ sx: { color: 'text.secondary' } }}
							secondary={lastTakenAt || 'None'}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							secondary={
								<Button onClick={() => onViewUsageHistory(asNeededMedication)} variant="text" color="inherit">
									View Usage History
								</Button>
							}
						/>
					</ListItem>
				</List>
			</MedsItemContent>
		</MedsItem>
	);
}

export default AsNeededMedListItem;
