import React, { useState, forwardRef, useMemo, useImperativeHandle } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DateField, DateFieldProps, PickersDay } from '@mui/x-date-pickers';
import { Box, ClickAwayListener, Paper, Popper, Stack } from '@mui/material';

interface DayPickerProps extends Omit<DateFieldProps<Dayjs>, 'value' | 'onChange'> {
	value?: number | null;
	onChange?: (newValue: number | null) => void;
}

export const DayPicker = forwardRef(({ value, onChange, ...restProps }: DayPickerProps, ref) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [internalValue, setInternalValue] = useState<number | null>(null);
	const [open, setOpen] = useState(false);
	const dateObj = dayjs('1964-01-01');

	const isControlled = value !== undefined && onChange !== undefined;
	const selectedDayObj = useMemo(() => {
		const selectedDay = isControlled ? value : internalValue;

		if (selectedDay) {
			return dateObj.date(selectedDay);
		}

		return null;
	}, [isControlled, value, internalValue]);

	const handleOpenPopper = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
		setOpen(true);
	};

	const handleClosePopper = () => {
		setOpen(false);
	};

	const handleDayChange = (date: Dayjs | null) => {
		const newDay = Number(date?.format('DD'));

		if (isControlled && onChange) {
			onChange(newDay);
		} else {
			setInternalValue(newDay);
		}
	};

	const handleDayPick = (date: Dayjs | null) => {
		handleDayChange(date);
		handleClosePopper();
	};

	useImperativeHandle(ref, () => ({
		focus: () => setOpen(true),
		openPicker: () => setOpen(true),
		closePicker: () => setOpen(false),
	}));

	return (
		<ClickAwayListener onClickAway={handleClosePopper}>
			<Stack
				onBlur={(event) => {
					if (!event.currentTarget?.contains(event.relatedTarget)) {
						handleClosePopper();
					}
				}}
			>
				<DateField
					value={selectedDayObj}
					format="DD"
					onClick={handleOpenPopper}
					onChange={handleDayChange}
					{...restProps}
				/>
				<Popper
					disablePortal
					open={open}
					anchorEl={anchorEl}
					placement="bottom-start"
					sx={{
						zIndex: (theme) => theme.zIndex.modal,
					}}
				>
					<Paper>
						<Box style={{ display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)', gap: '8px', padding: '10px' }}>
							{Array.from({ length: 31 }, (_, index) => {
								return (
									<PickersDay
										key={index + 1}
										day={dateObj.date(index + 1)}
										onDaySelect={handleDayPick}
										selected={selectedDayObj?.date() === index + 1}
										outsideCurrentMonth={false}
										isFirstVisibleCell={index === 0}
										isLastVisibleCell={index === 30}
									/>
								);
							})}
						</Box>
					</Paper>
				</Popper>
			</Stack>
		</ClickAwayListener>
	);

	// return (
	// 	<CalendarWeek>
	// 		{Array.from({ length: 31 }, (_, i) => (
	// 			<CalendarWeekDay key={i} day={i + 1} />
	// 		))}
	// 	</CalendarWeek>
	// );

	return <DateField value={selectedDayObj} format="DD" onChange={handleDayChange} {...restProps} />;
});

export default DayPicker;
