import { InputAdornment } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import React from 'react';
import { Dayjs } from 'dayjs';
import { DateValidationError, PickerChangeHandlerContext } from '@mui/x-date-pickers';
import DatePicker from '../datePicker/DatePicker';

interface IDateFilterProps {
	sx?: SystemStyleObject;
	name: string;
	label: string;
	value?: Dayjs | null | undefined;
	onChange?: (value: Dayjs | null, context: PickerChangeHandlerContext<DateValidationError>) => void;
}

export default function DateFilter({ sx, name, label, value, onChange }: IDateFilterProps) {
	return (
		<DatePicker
			label={label}
			value={value}
			onChange={onChange}
			sx={sx}
			slotProps={{
				field: { clearable: true },
				textField: {
					variant: 'outlined',
					size: 'small',
					InputProps: {
						name,
						startAdornment: (
							<InputAdornment position="start">
								<CalendarMonthOutlinedIcon />
							</InputAdornment>
						),
						endAdornment: null,
					},
				},
			}}
		/>
	);
}
