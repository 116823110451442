import React from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { WalkThroughScreen, WalkThroughScreenKeys } from 'components/patient/walkThrough/WalkThrough';

export const homeWalkThrough: WalkThroughScreen = {
	screenKey: WalkThroughScreenKeys.HOME,
	steps: [
		{
			target: 'body',
			title: 'Welcome to your ExactCare App!',
			content: 'Read these tips to get started.',
			placement: 'center',
		},
		{
			target: '.EcaListItem-todaysMedication',
			title: 'View your medication schedule and track the medications you take each day for peace of mind.',
			content: '',
			showProgress: true,
			styles: {
				tooltip: { width: '420px' },
			},
		},
		{
			target: '.EcaListItem-medicationLoggedThisWeek',
			title: 'Review or edit the medication you have logged.',
			content: '',
			showProgress: true,
			styles: {
				tooltip: { width: '420px' },
			},
		},
		{
			target: '.EcaLink-medication',
			title: (
				<>
					<ErrorIcon color="error" sx={{ fontSize: '2.25rem' }} />
					<br />
					Red icons in the app mean you have a new notification.
				</>
			),
			content: '',
			showProgress: true,
			styles: {
				tooltip: { width: '300px' },
			},
		},
		{
			target: '.EcaLink-help',
			title: 'Need help?',
			content: 'Click here when you have a question, no matter where you are in the app.',
			showProgress: true,
		},
		{
			target: '.EcaPaper-inboxMessages',
			title: 'Read important messages from us.',
			content: '',
			showProgress: true,
			placement: 'auto',
		},
		{
			target: '.EcaBox-mainHeader',
			title: 'That’s it! Click each option at the top to learn more.',
			content: '',
			styles: {
				tooltip: { width: '420px' },
			},
			disableOverlayClose: false,
		},
	],
};
