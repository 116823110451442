import React from 'react';
import { Alert, Button, Stack, Typography } from '@mui/material';
import Joi from 'joi';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import PasswordField from 'components/common/inputs/passwordField/PasswordField';
import { buildPasswordSchema } from 'utils/buildPasswordSchema';
import { useChangePasswordMutation } from 'hooks';
import { IChangePasswordRequest } from 'core/api/userAccount/userAccount.models';

const schema = Joi.object({
	password: Joi.string().required().messages({
		'string.empty': 'Current password cannot be empty',
		'any.required': 'Current password is required',
	}),
	newPassword: buildPasswordSchema(),
	repeatNewPassword: Joi.string().required().valid(Joi.ref('newPassword')).messages({
		'string.empty': 'Repeat new password cannot be empty',
		'any.required': 'Repeat new password is required',
		'any.only': 'New password and repeat new password must match',
	}),
});

interface ChangePasswordForm extends IChangePasswordRequest {
	repeatNewPassword: string;
}

export function ChangePassword() {
	const {
		control,
		handleSubmit,
		formState: { isValid },
	} = useForm<ChangePasswordForm>({ mode: 'onChange', resolver: joiResolver(schema) });
	const {
		mutate: changePasswordMutate,
		isPending,
		isSuccess,
		error: changePasswordError,
	} = useChangePasswordMutation();
	const handleSubmitChangePasswordForm = (formData: IChangePasswordRequest) => changePasswordMutate(formData);

	return (
		<>
			{isSuccess && (
				<Typography component="p" mt={2} color={(theme) => theme.palette.success.main}>
					Thank you for updating your password.
				</Typography>
			)}
			{!isSuccess && (
				<Stack gap={2} component="form" onSubmit={handleSubmit(handleSubmitChangePasswordForm)}>
					{changePasswordError?.message && (
						<Alert severity="error" sx={{ mb: 2 }}>
							{changePasswordError.message}
						</Alert>
					)}
					<Controller
						name="password"
						control={control}
						render={({ field, fieldState: { error } }) => (
							<PasswordField
								{...field}
								value={field.value || ''}
								autoComplete="current-password"
								helperText={error ? error.message : null}
								error={!!error}
								label="Current Password"
								variant="standard"
								size="small"
								disableTooltip
								fullWidth
							/>
						)}
					/>

					<Controller
						name="newPassword"
						control={control}
						render={({ field, fieldState: { error } }) => (
							<PasswordField
								{...field}
								value={field.value || ''}
								autoComplete="new-password"
								label="Enter New Password"
								variant="standard"
								size="small"
								fullWidth
								helperText={error ? error.message : null}
								error={!!error}
							/>
						)}
					/>

					<Controller
						name="repeatNewPassword"
						control={control}
						render={({ field, fieldState: { error } }) => (
							<PasswordField
								{...field}
								value={field.value || ''}
								autoComplete="new-password"
								helperText={error ? error.message : null}
								error={!!error}
								label="Repeat New Password"
								variant="standard"
								size="small"
								disableTooltip
								fullWidth
							/>
						)}
					/>
					<Button
						disabled={!isValid && isPending}
						variant="contained"
						color="primary"
						fullWidth
						type="submit"
						sx={{ mt: 2 }}
					>
						{isPending ? 'Loading...' : 'Save'}
					</Button>
				</Stack>
			)}
		</>
	);
}

export default ChangePassword;
