import './index.css';

// import './wdyr';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import QueryProvider from 'contexts/QueryProvider';
import { MainThemeProvider } from 'themes/MainTheme';
import Router from 'Router';
import { SnackbarProvider } from 'notistack';
import { HelmetProvider } from 'react-helmet-async';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<MainThemeProvider>
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<SnackbarProvider autoHideDuration={2000}>
				<QueryProvider>
					<React.StrictMode>
						<HelmetProvider>
							<Router />
						</HelmetProvider>
					</React.StrictMode>
				</QueryProvider>
			</SnackbarProvider>
		</LocalizationProvider>
	</MainThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
