export enum MedicationAction {
	GET_PRESCRIBER_REFILL_REQUEST_FORM = 'GET_PRESCRIBER_REFILL_REQUEST_FORM',
	REQUEST_REFILL_FROM_ECP = 'REQUEST_REFILL_FROM_ECP',
}

export enum MedicationRefillAlert {
	NO_REFILLS = 'NO_REFILLS',
	REFILL_DENIED = 'REFILL_DENIED',
}

export enum MedicationOnboardingAlert {
	REFILL_TOO_SOON = 'Refill Too Soon',
	VERIFY_IF_PATIENT_TAKING_MED = 'Verify if Patient Taking Med',
	DRUG_NOT_COVERED = 'Drug Not Covered',
}

export enum MedicationRefillsStatus {
	REFILLS_STATUS_NONE = 'None',
	REFILLS_STATUS_LOW = 'Low',
	REFILLS_STATUS_YES = 'Yes',
}

export enum MedicationRefillsStatusAlertLevel {
	REFILLS_ALERT_LEVEL_DANGER = 'danger',
	REFILLS_ALERT_LEVEL_WARNING = 'warning',
}

export interface IMedication {
	id: number | null;
	sfid: string | null;
	name: string | null;
	imageUrl: string | null;
	pack: string | null;
	refillsRemaining: number | null;
	actions: MedicationAction[] | null;
	refillAlert: MedicationRefillAlert | null;
	onboardingAlerts: MedicationOnboardingAlert[] | null;
	rxNumber: string | null;
	rxStatus: string | null;
	directions: string | null;
	whenToTake: string | null;
	prescriberName: string | null;
	prescriberPhone: string | null;
	prescriber: string | null;
	commonUses: string | null;
	howToUse: string | null;
	sideEffects: string | null;
	precautions: string | null;
	status: string | null;
	refillsStatus: string | null;
	refillsStatusAlertLevel: string | null;
}

export interface IGetMedicationsResponse {
	medications: IMedication[];
	anyOrderReceived: boolean;
}

export interface IGetRefillRequestFormPdfRequestParams {
	prescriberSfid: string;
	currentMedicationSfid?: string;
}

export interface IGetMedicationSummaryRequestParams {
	sfid: string;
}

export interface IGetMedicationSummaryResponse extends IMedication {}

export interface IRequestRefillParams {
	sfid: string;
}
