import React from 'react';
import { Stack, SxProps, Theme, useMediaQuery } from '@mui/material';
import AnewHealthExactCareLightLogo from 'components/logo/AnewHealthExactCareLightLogo';

interface AuthMainContentProps {
	children: React.ReactNode;
	sx?: SxProps<Theme>;
}

export function AuthMainContent({ children, sx }: AuthMainContentProps) {
	const upSmallScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));

	return (
		<Stack
			width={{ xs: '100%', md: '65%' }}
			padding={{ xs: '0.75rem', md: '6rem 5rem' }}
			direction="column"
			gap={2}
			justifyContent="center"
			sx={{
				backgroundColor: { md: 'background.secondary' },
				...sx,
			}}
		>
			{!upSmallScreen && <AnewHealthExactCareLightLogo sx={{ width: '240px', height: 'auto' }} />}
			{children}
		</Stack>
	);
}

export default AuthMainContent;
