import { Theme } from '@mui/system';

export default {
	styleOverrides: {
		root: ({ theme }: { theme: Theme }) => ({
			'& .MuiBackdrop-root': {
				backdropFilter: 'blur(10px)',
				background:
					'radial-gradient(108.65% 192.72% at 79.45% -8.42%, rgba(71, 10, 104, 0.017) 0%, rgba(255, 255, 255, 0) 100%)',
			},

			'& .MuiDialog-paper': {
				borderRadius: '20px',
				backgroundColor: theme.palette.background.default,
				boxShadow: '0px 12px 60px 0px rgba(24, 24, 24, 0.12)',

				'&>.MuiIconButton-root': {
					top: '16px',
					right: '16px',
				},
			},

			'& .MuiDialogTitle-root': {
				padding: '24px',
			},

			'& .MuiDialogActions-root': {
				padding: '0 24px 24px 24px',
			},

			[theme.breakpoints.down('sm')]: {
				top: '5%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'flex-end',
				'& .MuiDialog-container': {
					alignItems: 'flex-end',
				},

				'& .MuiDialog-paper': {
					borderRadius: '20px 20px 0 0',
					width: '100%',
					margin: 0,
				},
			},
		}),
	},
};
