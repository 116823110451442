import { queryOptions } from '@tanstack/react-query';
import { getBadges } from 'core/api/badges/badges.api';

export const badgeQueries = {
	getBadges: () =>
		queryOptions({
			queryKey: ['badges'],
			queryFn: getBadges,
		}),
};
