import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function ArrowIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 10 20" {...props}>
			<path
				d="M0.361879 18.8212C0.242662 18.711 0.182087 18.5674 0.180153 18.3905C0.178237 18.2135 0.238812 18.0635 0.361879 17.9405L8.33305 9.96931L0.342653 1.99816C0.22342 1.87893 0.162845 1.7363 0.160929 1.57029C0.159012 1.40425 0.225995 1.2597 0.361879 1.13661C0.481112 1.01355 0.630154 0.947847 0.809004 0.939513C0.987854 0.93118 1.13882 0.988546 1.2619 1.11161L9.63305 9.48279C9.70357 9.56612 9.7555 9.64625 9.78883 9.72316C9.82216 9.80008 9.83883 9.88213 9.83883 9.96931C9.83883 10.0693 9.82216 10.1578 9.78883 10.2347C9.7555 10.3116 9.70357 10.3853 9.63305 10.4559L1.24265 18.8462C1.12342 18.9655 0.980795 19.0251 0.814779 19.0251C0.648745 19.0251 0.497779 18.9571 0.361879 18.8212Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
