import React from 'react';
import { Typography, Box } from '@mui/material';
import DeliveriesList from 'components/patient/delivery/deliveriesList';
import { WalkThroughScreen, WalkThroughScreenKeys } from 'components/patient/walkThrough/WalkThrough';
import { DeliveryRecordType } from 'core/models/delivery/delivery.models';

export const deliveriesWalkThrough: WalkThroughScreen = {
	screenKey: WalkThroughScreenKeys.DELIVERIES,
	steps: [
		{
			title: 'Click on a delivery to view the order summary and tracking number.',
			content: (
				<>
					<Typography component="p" fontWeight={500} color="text.secondary" mb={2}>
						Here’s an example.
					</Typography>
					<Box sx={{ borderRadius: '12px', border: '1px solid', borderColor: 'divider', p: 2 }}>
						<DeliveriesList
							items={[
								{
									sfid: '1234',
									orderName: 'Monthly Delivery',
									orderNumber: '12345678',
									orderStatus: null,
									recordType: DeliveryRecordType.ROUTINE,
									isFirstExactPack: null,
									displayStatus: 'Delivery (Date)',
									deliveryDate: null,
								},
							]}
							hasOrderItems
							activeOrder="1234"
							title="Upcoming Orders"
						/>
					</Box>
				</>
			),
			styles: {
				tooltip: { width: '360px' },
			},
			placement: 'center',
			target: 'body',
			disableOverlayClose: false,
		},
	],
};
