import React, { useMemo } from 'react';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useParams } from 'react-router-dom';
import { useMedications } from 'hooks/patient/useMedications';
import { medsGroupedByPacks } from 'core/api/medications/medications.api';
import { Theme, useMediaQuery } from '@mui/material';
import useShowSidebarNavigation from 'hooks/useShowSidebarNavigation';
import { MedicationSidebar } from '../medicationSidebar';

interface MedicationLayoutProps {
	children: React.ReactNode;
}

export function MedicationLayout({ children }: MedicationLayoutProps) {
	const { sfid: activeSfid } = useParams();
	const { getMedicationsQuery } = useMedications();
	const { data, isLoading: medicationsIsLoading } = getMedicationsQuery();
	const medications = data?.medications || [];
	const anyOrderReceived = data?.anyOrderReceived || false;

	const medsGroupedByPacksData = useMemo(() => {
		return medications.length > 0 ? medsGroupedByPacks(medications) : null;
	}, [medications]);

	const xsScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.only('xs'));
	const showSidebarNavigation = useShowSidebarNavigation();

	if (xsScreen) {
		if (showSidebarNavigation) {
			return (
				<Grid xs={12}>
					<MedicationSidebar
						medsGroupedByPacks={medsGroupedByPacksData}
						{...{ medicationsIsLoading, activeSfid, anyOrderReceived }}
					/>
				</Grid>
			);
		}

		return <Grid xs={12}>{children}</Grid>;
	}

	return (
		<>
			<Grid sm={4} xs={12}>
				<MedicationSidebar
					medsGroupedByPacks={medsGroupedByPacksData}
					{...{ medicationsIsLoading, activeSfid, anyOrderReceived }}
				/>
			</Grid>
			<Grid sm={8} xs={12}>
				{children}
			</Grid>
		</>
	);
}

export default MedicationLayout;
