const routes = {
	home: '/',
	login: '/login',
	logout: '/logout',
	register: '/register',
	changePassword: '/change-password/:hash',
	changeEmailConfirm: '/change-email/confirm/:hash',
	forgotPassword: '/password-recovery',
	delivery: '/deliveries/:sfid?',
	medication: '/medication/:sfid?',
	account: {
		personalInformation: '/account/personal-information',
		paymentsAndBilling: '/account/payments-and-billing',
		notificationsPreferences: '/account/notifications-preferences',
	},
	mySchedule: {
		todaysMedication: '/my-schedule/todays-medication',
		todaysMedDetails: '/my-schedule/todays-medication/:id',
		logAsNeededMed: '/my-schedule/log-as-needed-medication',
		manageMedicationReminders: '/my-schedule/manage-medication-reminders',
		reviewMyHistory: {
			calendar: '/my-schedule/review-my-history',
			day: '/my-schedule/review-my-history/:date/:id?',
		},
	},
	inbox: {
		item: '/inbox/message/:messageId',
		list: '/inbox?/:tab?/:page?',
	},
	admin: {
		home: '/admin/patients',
		patients: {
			list: '/admin/patients',
			create: '/admin/patients/create',
			patient: {
				medSync: '/admin/patients/:patientId/med-sync',
				notifications: '/admin/patients/:patientId/notifications',
				metaData: '/admin/patients/:patientId/meta-data',
				pushDevices: '/admin/patients/:patientId/push-devices',
			},
		},
		admins: {
			list: '/admin/admins',
		},
		enterprise: {
			list: '/admin/enterprise',
			caregiver: {
				list: '/admin/enterprise/:enterpriseId/caregivers',
			},
		},
	},
	enterprise: {
		home: '/enterprise',
		careTeamMembers: '/enterprise/care-team-members',
		patients: {
			list: '/enterprise/patients',
			patient: {
				profile: '/enterprise/patients/:patientId/profile',
				medications: '/enterprise/patients/:patientId/medications',
				deliveries: '/enterprise/patients/:patientId/deliveries',
				inbox: '/enterprise/patients/:patientId/inbox',
				mySchedule: '/enterprise/patients/:patientId/my-schedule',
			},
		},
	},
	errors: {
		unauthorizedAccess: '/unauthorized',
		notFound: '/404',
	},
};

export default routes;
