import { Theme } from '@mui/material/styles';

export default {
	styleOverrides: {
		root: ({ theme }: { theme: Theme }) => ({
			borderTop: `1px solid ${theme.palette.divider}`,
			backgroundColor: 'transparent',
			'&:not(:last-child)': {
				borderBottom: 0,
			},
			'&:before': {
				display: 'none',
			},
			'.MuiAccordion-root,.Mui-expanded,.MuiAccordionDetails-root': {
				border: 'none',
			},

			'& .MuiAccordionSummary-root': {
				backgroundColor: 'transparent',
				flexDirection: 'row-reverse',
				'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
					transform: 'rotate(90deg)',
				},
				'& .MuiAccordionSummary-content': {
					marginLeft: theme.spacing(1),
					color: theme.palette.text.secondary,
				},
			},

			'& .MuiAccordionDetails-root': {
				padding: theme.spacing(2),
				borderTop: '1px solid rgba(0, 0, 0, .125)',
			},
		}),
	},
};
