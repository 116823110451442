import React, { useEffect } from 'react';
import ContentCard from 'components/card/ContentCard';
import { Button, Box, Grid, Typography } from '@mui/material';
import Switch from 'components/common/inputs/switch/Switch';
import { TimeZoneSelect } from 'components/common/inputs';
import ReminderSettings from 'components/patient/mySchedule/ReminderSettings';
import { useMedicationRemindersMutation, useMedicationRemindersQuery } from 'hooks/patient';
import { useUserContext } from 'contexts/UserProvider';
import Suspense from 'components/Suspense';
import { useSnackbar } from 'notistack';
import { IScheduledTimeReminderSettings } from 'core/api/mySchedule/mySchedule.models';

export default function ManageMedicationRemindersPage() {
	const { enqueueSnackbar } = useSnackbar();
	const {
		updateTimeZoneMutation,
		updateMedicationRemindersStateMutation,
		updateTimeReminderMutation,
		resetAllTimeRemindersMutation,
	} = useMedicationRemindersMutation();
	const { medicationRemindersEnabled, timeZone, isLoading: isLoadingUserContext } = useUserContext();
	const { data: settingsData, isLoading: settingsDataIsLoading } = useMedicationRemindersQuery();
	const { items: reminders } = settingsData || {};
	const handleSuccess = () => {
		enqueueSnackbar('Your changes have been saved', { variant: 'success' });
	};

	const handleChangeMedicationRemindersEnabled = (isEnabled: boolean | null) =>
		updateMedicationRemindersStateMutation.mutate(isEnabled, {
			onSuccess: handleSuccess,
		});
	const handleChangeReminder = (reminder: Partial<IScheduledTimeReminderSettings>) =>
		updateTimeReminderMutation.mutate(reminder, {
			onSuccess: handleSuccess,
		});
	const handleResetAllTimeReminders = () =>
		resetAllTimeRemindersMutation.mutate(undefined, { onSuccess: handleSuccess });
	const handleUpdateTimeZone = (val: string | null) =>
		updateTimeZoneMutation.mutate(val, {
			onSuccess: handleSuccess,
		});

	useEffect(() => {
		if (reminders && reminders.every((reminder) => !reminder.enabled)) {
			handleChangeMedicationRemindersEnabled(false);
		}
	}, [reminders]);

	return (
		<ContentCard title="Manage Medication Reminders">
			<Typography variant="body1" color="text.secondary">
				Taking your medication as prescribed is important to your health. You can set up reminders to help you remember
				to take them!
			</Typography>

			<Grid container>
				<Grid item xs={12} sm={8}>
					<Typography variant="h2" fontWeight={700} color="text.secondary">
						Do you want to receive reminders to take your medication?
					</Typography>
					<Switch
						labelOn="Yes"
						labelOff="No"
						checked={medicationRemindersEnabled ?? false}
						onChange={(event) => handleChangeMedicationRemindersEnabled(event.target.checked)}
						disabled={updateMedicationRemindersStateMutation.isPending || isLoadingUserContext}
						labelProps={{ slotProps: { typography: { color: 'text.secondary' } } }}
					/>
				</Grid>
				<Grid item xs={12} sm={4}>
					<Typography variant="body1" fontWeight={700} color="text.secondary">
						Time Zone
					</Typography>
					<TimeZoneSelect
						variant="standard"
						size="small"
						fullWidth
						onChange={(event) => {
							handleUpdateTimeZone(event.target.value);
						}}
						value={timeZone || ''}
						disabled={updateTimeZoneMutation.isPending || isLoadingUserContext}
					/>
				</Grid>
			</Grid>

			{medicationRemindersEnabled && (
				<Suspense isLoading={settingsDataIsLoading}>
					<ReminderSettings
						reminders={reminders}
						isLoading={updateTimeReminderMutation.isPending}
						onChangeReminder={handleChangeReminder}
					/>
					<Box>
						<Button
							onClick={handleResetAllTimeReminders}
							variant="outlined"
							color="inherit"
							size="small"
							disabled={resetAllTimeRemindersMutation.isPending}
						>
							{resetAllTimeRemindersMutation.isPending ? 'Loading...' : 'Reset to Prescribed Times'}
						</Button>
					</Box>
				</Suspense>
			)}
		</ContentCard>
	);
}
