import React, { useMemo } from 'react';
import ReactMarkdown, { Components } from 'react-markdown';
import remarkGfm from 'remark-gfm';
import {
	Button,
	Link,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { OverridableTypeMap, OverrideProps } from '@mui/material/OverridableComponent';

interface LinkComponentProps {
	href?: string;
	title?: string;
	children?: React.ReactNode;
}

function LinkComponent({ href, title, children }: LinkComponentProps) {
	const isNewTab = href && !href.startsWith(window.location.origin);

	if (title && title.startsWith('btn-')) {
		return (
			<Button
				component={Link}
				href={href}
				variant="contained"
				color={title.slice(4) as 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'}
				sx={{ margin: '0.375rem 0' }}
				target={isNewTab ? '_blank' : undefined}
			>
				{children}
			</Button>
		);
	}

	if (href && href.startsWith('tel:')) {
		return (
			<Typography component="span" fontWeight={700}>
				{children}
			</Typography>
		);
	}

	return (
		<Link href={href} underline="always" title={title} target={isNewTab ? '_blank' : undefined}>
			{children}
		</Link>
	);
}

function createMuiComponent(Component: React.ElementType) {
	return function MuiComponent({ children, ...props }: OverrideProps<OverridableTypeMap, typeof Component>) {
		return <Component {...props}>{children}</Component>;
	};
}

interface MarkdownProps {
	children?: string | null;
}

export default function Markdown({ children: markdown }: MarkdownProps) {
	const components: Components = useMemo(
		() => ({
			a: (props) => LinkComponent(props),
			p: ({ children }) => createMuiComponent(Typography)({ children, variant: 'body2', mb: '1.125rem' }),
			del: ({ children }) =>
				createMuiComponent(Typography)({
					sx: { mb: '1.125rem', textDecoration: 'line-through' },
					children,
					variant: 'body2',
				}),
			em: ({ children }) =>
				createMuiComponent(Typography)({ sx: { mb: '1.125rem', fontStyle: 'italic' }, children, variant: 'body2' }),
			strong: ({ children }) =>
				createMuiComponent(Typography)({
					component: 'strong',
					sx: { mb: '1.125rem', fontWeight: 'bold' },
					children,
					variant: 'body2',
				}),
			b: ({ children }) =>
				createMuiComponent(Typography)({
					component: 'b',
					sx: { mb: '1.125rem', fontWeight: 'bold' },
					children,
					variant: 'body2',
				}),
			h1: ({ children }) =>
				createMuiComponent(Typography)({ component: 'h1', variant: 'h1', sx: { mb: '1.125rem' }, children }),
			h2: ({ children }) =>
				createMuiComponent(Typography)({ component: 'h2', variant: 'h2', sx: { mb: '1.125rem' }, children }),
			h3: ({ children }) =>
				createMuiComponent(Typography)({ component: 'h3', variant: 'h3', sx: { mb: '1.125rem' }, children }),
			h4: ({ children }) =>
				createMuiComponent(Typography)({ component: 'h4', variant: 'h4', sx: { mb: '1.125rem' }, children }),
			h5: ({ children }) =>
				createMuiComponent(Typography)({ component: 'h5', variant: 'h5', sx: { mb: '1.125rem' }, children }),
			h6: ({ children }) =>
				createMuiComponent(Typography)({ component: 'h6', variant: 'h6', sx: { mb: '1.125rem' }, children }),
			ul: ({ children }) =>
				createMuiComponent(Typography)({ component: 'ul', variant: 'body2', sx: { m: 0 }, children }),
			li: ({ children }) =>
				createMuiComponent(Typography)({ component: 'li', variant: 'body2', sx: { mb: '1.125rem' }, children }),
			table: ({ children }) =>
				createMuiComponent(TableContainer)({
					component: Paper,
					sx: { mb: 2 },
					children: createMuiComponent(Table)({ size: 'small', children }),
				}),
			tbody: ({ children }) => createMuiComponent(TableBody)({ children }),
			th: ({ children }) => createMuiComponent(TableHead)({ children }),
			tr: ({ children }) => createMuiComponent(TableRow)({ children }),
			td: ({ children }) => createMuiComponent(TableCell)({ children }),
		}),
		[]
	);

	return (
		<ReactMarkdown
			urlTransform={(url) => {
				return url;
			}}
			remarkPlugins={[remarkGfm]}
			components={components}
		>
			{markdown}
		</ReactMarkdown>
	);
}
