import React from 'react';
import { Tab, Tabs, styled } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const InboxMessagesTabsStyled = styled(Tabs)(({ theme }) => ({
	borderBottom: 1,
	borderColor: 'divider',
	'& .MuiTab-root': {
		'&.Mui-selected': {
			color: theme.palette.text.secondary,
		},
		color: theme.palette.text.primary,
		textTransform: 'capitalize',
		fontWeight: 600,
		textAlign: 'center',
		fontSize: '1.25rem',
		lineHeight: '1.625rem',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.125rem',
			lineHeight: '1.375rem',
		},
	},
}));

interface InboxMessagesTabsProps {
	value: string;
	tabs: {
		label: string;
		key: string;
		to: string;
	}[];
}

export function InboxMessagesTabs({ value, tabs }: InboxMessagesTabsProps) {
	return (
		<InboxMessagesTabsStyled value={value}>
			{tabs.map(({ key, label, to }) => (
				<Tab
					key={key}
					value={key}
					label={label}
					component={RouterLink}
					to={to}
					state={{ showSidebarNavigation: false }}
				/>
			))}
		</InboxMessagesTabsStyled>
	);
}

export default InboxMessagesTabs;
