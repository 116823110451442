import React from 'react';
import { Button, Box, ButtonProps } from '@mui/material';

interface IFiltersBarProps {
	children: React.ReactNode;
	onClearFilters?: () => void;
	buttonProps?: Omit<ButtonProps, 'children'>;
}

export default function FiltersBar({ children, onClearFilters, buttonProps }: IFiltersBarProps) {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				gap: '10px',
				paddingBottom: '10px',
				flexWrap: 'nowrap',
				justifyContent: 'space-evenly',
			}}
		>
			{children}
			{onClearFilters && (
				<Button onClick={onClearFilters} {...buttonProps}>
					Clear filters
				</Button>
			)}
		</Box>
	);
}
