import React, { useEffect } from 'react';
import { Button, Chip, Typography, Stack, useMediaQuery, Theme } from '@mui/material';
import ContentCard from 'components/card/ContentCard';
import Dialog from 'components/dialog';
import { useDisclosure } from 'hooks';
import { useUserContext } from 'contexts/UserProvider';
import {
	DeleteAccount,
	UpdateMobileNumber,
	ChangePassword,
	ChangeAddress,
	ChangeEmail,
} from 'components/patient/account';
import { useAccountEmailMutation } from 'hooks/userAccount/useAccountEmailMutation';

function PersonalInformationPage() {
	const {
		email,
		billingAddress,
		requestedBillingAddress,
		shippingAddress,
		requestedShippingAddress,
		phoneNumber = null,
		emailValidated,
	} = useUserContext();
	const { resendVerificationEmailMutation } = useAccountEmailMutation();

	const handleResendVerificationEmailButton = () => resendVerificationEmailMutation.mutate();

	const { isOpen: openUpdateMobileNumberDialog, onToggle: toggleUpdateMobileNumberDialog } = useDisclosure();
	const { isOpen: openChangeEmailDialog, onToggle: toggleChangeEmailDialog } = useDisclosure();
	const { isOpen: openDeleteAccountDialog, onToggle: toggleDeleteAccountDialog } = useDisclosure();
	const { isOpen: openChangePasswordDialog, onToggle: toggleChangePasswordDialog } = useDisclosure();
	const { isOpen: openChangeAddressDialog, onToggle: toggleChangeAddressDialog } = useDisclosure();
	const {
		isOpen: isShowChangeAddressSuccessMessage,
		onOpen: showChangeAddressSuccessMessage,
		onClose: hideChangeAddressSuccessMessage,
	} = useDisclosure();

	const upSmallScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));

	useEffect(() => {
		if (!openChangeAddressDialog) {
			hideChangeAddressSuccessMessage();
		}
	}, [openChangeAddressDialog]);

	const updateMobileNumberDialog = (
		<Dialog
			title={phoneNumber ? 'Update Mobile Number' : 'Add a Mobile Number'}
			scroll="paper"
			fullWidth
			maxWidth="xs"
			open={openUpdateMobileNumberDialog}
			onClose={toggleUpdateMobileNumberDialog}
		>
			<UpdateMobileNumber />
		</Dialog>
	);

	const changeEmailDialog = (
		<Dialog
			title="Update email"
			scroll="paper"
			fullWidth
			maxWidth="xs"
			open={openChangeEmailDialog}
			onClose={toggleChangeEmailDialog}
		>
			<ChangeEmail />
		</Dialog>
	);

	const deleteAccountDialog = (
		<Dialog
			title="Delete My Online Access"
			scroll="paper"
			fullWidth
			maxWidth="xs"
			open={openDeleteAccountDialog}
			onClose={toggleDeleteAccountDialog}
		>
			<DeleteAccount />
		</Dialog>
	);

	const changePasswordDialog = (
		<Dialog
			title="Update Password"
			scroll="paper"
			fullWidth
			maxWidth="xs"
			open={openChangePasswordDialog}
			onClose={toggleChangePasswordDialog}
		>
			<ChangePassword />
		</Dialog>
	);

	const changeAddressDialog = (
		<Dialog
			title={isShowChangeAddressSuccessMessage ? 'Address Updated' : undefined}
			scroll="paper"
			fullWidth
			maxWidth={isShowChangeAddressSuccessMessage ? 'xs' : 'md'}
			open={openChangeAddressDialog}
			onClose={toggleChangeAddressDialog}
		>
			<ChangeAddress onSuccess={showChangeAddressSuccessMessage} />
		</Dialog>
	);

	return (
		<ContentCard title="Personal Information" sx={{ color: 'text.secondary' }}>
			<Typography variant="body1" component="p" color="text.secondary">
				It&apos;s important we have your most up-to-date contact information. Anytime this changes, please let us know
				or edit it below.
			</Typography>
			<Stack gap="6px">
				<Stack direction="row" gap={1} alignItems="center" justifyContent={{ xs: 'space-between', sm: 'flex-start' }}>
					<Typography variant="h2" fontWeight={600}>
						Mobile Phone
					</Typography>
					{phoneNumber && (
						<Button
							onClick={toggleUpdateMobileNumberDialog}
							variant={upSmallScreen ? 'text' : 'outlined'}
							color={upSmallScreen ? 'secondary' : 'inherit'}
						>
							edit
						</Button>
					)}
				</Stack>
				<Typography component="span" variant="body1">
					{phoneNumber || (
						<Button onClick={toggleUpdateMobileNumberDialog} variant="outlined" size="small">
							Add a mobile number
						</Button>
					)}
				</Typography>
			</Stack>
			{updateMobileNumberDialog}
			<Stack gap="6px">
				<Stack direction="row" gap={1} alignItems="center" justifyContent={{ xs: 'space-between', sm: 'flex-start' }}>
					<Typography variant="h2" fontWeight={600}>
						Address{' '}
						{(requestedShippingAddress || requestedBillingAddress) && (
							<Chip label="Update Pending" size="small" color="warning" />
						)}
					</Typography>
					<Button
						onClick={toggleChangeAddressDialog}
						variant={upSmallScreen ? 'text' : 'outlined'}
						color={upSmallScreen ? 'secondary' : 'inherit'}
					>
						edit
					</Button>
				</Stack>
				<Typography component="span" variant="body1">
					<b>Shipping:</b> {requestedShippingAddress || shippingAddress}
				</Typography>
				<Typography component="span" variant="body1">
					<b>Billing:</b> {requestedBillingAddress || billingAddress}
				</Typography>
			</Stack>
			{changeAddressDialog}
			<Stack gap="6px">
				<Stack direction="row" gap={1} alignItems="center" justifyContent={{ xs: 'space-between', sm: 'flex-start' }}>
					<Typography variant="h2" fontWeight={600}>
						Email
					</Typography>
					<Button
						onClick={toggleChangeEmailDialog}
						variant={upSmallScreen ? 'text' : 'outlined'}
						color={upSmallScreen ? 'secondary' : 'inherit'}
					>
						edit
					</Button>
				</Stack>
				<Stack direction="row" gap={1}>
					<Typography component="span" variant="body1">{`${email} `}</Typography>
					{!emailValidated && (
						<>
							<Typography component="span" color="error" fontWeight={700}>
								Not Verified
							</Typography>
							<Button
								onClick={handleResendVerificationEmailButton}
								variant="text"
								sx={{ fontWeight: 700 }}
								color="secondary"
							>
								{resendVerificationEmailMutation.isPending ? 'loading...' : 'resend verification email'}
							</Button>
							<Typography component="strong" variant="small" color="success.main">
								Email has been sent
							</Typography>
						</>
					)}
				</Stack>
			</Stack>
			{changeEmailDialog}
			<Stack gap="6px">
				<Stack direction="row" gap={1} alignItems="center" justifyContent={{ xs: 'space-between', sm: 'flex-start' }}>
					<Typography variant="h2" fontWeight={600}>
						Password
					</Typography>
					<Button
						onClick={toggleChangePasswordDialog}
						variant={upSmallScreen ? 'text' : 'outlined'}
						color={upSmallScreen ? 'secondary' : 'inherit'}
					>
						change
					</Button>
				</Stack>
				<Typography component="span" variant="body1">
					***************
				</Typography>
			</Stack>
			{changePasswordDialog}
			<Typography component="p" textAlign="right">
				<Button
					onClick={toggleDeleteAccountDialog}
					variant={upSmallScreen ? 'text' : 'outlined'}
					color={upSmallScreen ? 'secondary' : 'inherit'}
				>
					Delete Account
				</Button>
			</Typography>
			{deleteAccountDialog}
		</ContentCard>
	);
}

export default PersonalInformationPage;
