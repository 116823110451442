import React, { useEffect } from 'react';
import { Box, CircularProgress, Link, Typography } from '@mui/material';
import routes from 'routes';
import { AuthLayout, AuthSidebar } from 'components/auth';
import ImageCover from 'components/ImageCover';
import { useAccountEmailMutation } from 'hooks/userAccount/useAccountEmailMutation';
import { useParams } from 'react-router-dom';

export default function ChangeEmailConfirmPage() {
	const { hash } = useParams();
	const { verifyEmailChangedMutation } = useAccountEmailMutation();

	useEffect(() => {
		if (hash != null) {
			verifyEmailChangedMutation.mutate({ hash });
		}
	}, [hash]);

	return (
		<AuthLayout>
			<AuthSidebar>
				<Typography component="h1" fontSize="2.313rem" lineHeight="2.5rem" fontWeight="bold" color="text.secondary">
					Email changed
				</Typography>
				{verifyEmailChangedMutation.isSuccess && (
					<Box mt={10} mb={10}>
						<Typography component="div" color="text.secondary" mb={2}>
							Your email has been verified.
						</Typography>
						<Typography component="span" color="text.secondary" variant="body1">
							<Link href={routes.home}>Go to Dashboard</Link>
						</Typography>
					</Box>
				)}
				{verifyEmailChangedMutation.isPending && (
					<Box sx={{ display: 'flex', padding: '6.25rem 0', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
						<CircularProgress />
					</Box>
				)}
				{verifyEmailChangedMutation.error?.message && (
					<Box sx={{ padding: '4.25rem 0' }}>
						<Typography component="h3" color="text.secondary" mb={2}>
							Something went wrong. Please try again later.
						</Typography>
						<Typography component="p" color="text.secondary" mb={2}>
							<strong>{verifyEmailChangedMutation.error?.message}</strong>
						</Typography>
					</Box>
				)}
			</AuthSidebar>
			<ImageCover
				src="/images/auth-seniors.png"
				sx={{
					display: { sm: 'none', md: 'flex' },
					flexGrow: 2,
				}}
			/>
		</AuthLayout>
	);
}
