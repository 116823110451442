import React, { useEffect } from 'react';
import { Button, Divider, Link, Stack, Typography } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import InboxIcon from '@mui/icons-material/Inbox';
import { useNavigate, useParams, Link as RouterLink, useLocation, generatePath } from 'react-router-dom';
import routes from 'routes';
import { InboxStatus } from 'core/api/inboxNotification';
import { ContentCard } from 'components/card';
import Markdown from 'components/Markdown/Markdown';
import { useInboxNotificationQuery, useUpdateInboxNotificationMutation } from 'hooks';
import relativeDateFormat from 'utils/relativeDateFormat';
import { useQueryClient } from '@tanstack/react-query';
import Suspense from 'components/Suspense';

export default function InboxMessagePage() {
	const navigate = useNavigate();
	const location = useLocation();
	const params = useParams();
	const queryClient = useQueryClient();
	const messageId = Number(params.messageId);
	const { data, isLoading } = useInboxNotificationQuery({ id: messageId });
	const { mutate: updateInboxNotification } = useUpdateInboxNotificationMutation();
	const { subject, createdAt, body, statusId } = data || {};
	const markdownContent = `${body}`.trim();
	const backTo = location.state?.backTo || generatePath(routes.inbox.list, { tab: 'all' });
	const handleClickDelete = () =>
		updateInboxNotification({ id: messageId, statusId: InboxStatus.Deleted }, { onSuccess: () => navigate(backTo) });
	const handleClickMoveToInbox = () => updateInboxNotification({ id: messageId, statusId: InboxStatus.Inbox });
	const isDeleted = statusId === InboxStatus.Deleted;

	useEffect(() => {
		if (!isLoading) {
			queryClient.invalidateQueries({ queryKey: ['badges'] });
		}
	}, [isLoading]);

	return (
		<ContentCard
			sx={{
				background: (theme) => ({ xs: theme.palette.background.default }),
			}}
		>
			<Stack
				direction="row"
				gap={1}
				justifyContent="space-between"
				alignItems="flex-end"
				sx={{
					display: { xs: 'none', sm: 'flex' },
				}}
			>
				<Link
					component={RouterLink}
					to={backTo}
					fontWeight="bold"
					underline="none"
					variant="body1"
					color="secondary.main"
					alignItems="center"
					sx={{ display: 'flex' }}
				>
					<ChevronLeftIcon />
					Back to Messages
				</Link>
			</Stack>
			<Divider sx={{ display: { xs: 'none', sm: 'block' } }} />
			<Suspense isLoading={isLoading}>
				<Stack direction="row" gap={1} alignItems="center" justifyContent="space-between">
					<Typography variant="caption" sx={{ flex: 1 }}>
						{relativeDateFormat(createdAt || '')}
					</Typography>
					{!isDeleted ? (
						<Button
							onClick={handleClickDelete}
							startIcon={<DeleteOutlinedIcon />}
							variant="text"
							size="small"
							sx={{ color: 'text.primary', fontWeight: 400 }}
						>
							Delete Message
						</Button>
					) : (
						<Button
							onClick={handleClickMoveToInbox}
							startIcon={<InboxIcon />}
							variant="text"
							size="small"
							sx={{ color: 'text.primary', fontWeight: 400 }}
						>
							Move to Inbox
						</Button>
					)}
				</Stack>
				<Stack direction="column" gap={1} alignItems="stretch">
					<Typography variant="h1" fontWeight={700} color="text.secondary">
						{subject}
					</Typography>
					<Typography component={Stack} variant="body2" color="text.secondary" mt="1.125rem">
						<Markdown>{markdownContent}</Markdown>
					</Typography>
				</Stack>
			</Suspense>
		</ContentCard>
	);
}
