import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function PillBottleIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 37 46" {...props}>
			<path
				d="M22.2496 42.3498H32.8996C34.0996 42.3498 35.1496 41.4498 35.1496 40.0998V12.7998C35.1496 11.5998 34.2496 10.6998 33.0496 10.5498H11.4496C10.2496 10.5498 9.34961 11.5998 9.34961 12.7998V34.8498"
				stroke="currentColor"
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinejoin="round"
				fill="none"
			/>
			<path
				d="M18.3498 34.8499H6.1998C3.6498 34.8499 1.5498 36.9499 1.5498 39.4999C1.5498 42.0499 3.6498 44.1499 6.1998 44.1499H18.3498C20.8998 44.1499 22.9998 42.0499 22.9998 39.4999C22.9998 36.9499 20.8998 34.8499 18.3498 34.8499Z"
				stroke="currentColor"
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinejoin="round"
				fill="none"
			/>
			<path
				d="M12.1997 36.6499V42.3499"
				stroke="currentColor"
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinejoin="round"
				fill="none"
			/>
			<path
				d="M10.8496 1.3999H33.4996V7.8499H10.8496V1.3999Z"
				stroke="currentColor"
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinejoin="round"
				fill="none"
			/>
			<path
				d="M14.1499 14.75V34.85"
				stroke="currentColor"
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinejoin="round"
				fill="none"
			/>
			<path
				d="M12.3496 7.84985V10.5499"
				stroke="currentColor"
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinejoin="round"
				fill="none"
			/>
			<path
				d="M32 7.84985V10.5499"
				stroke="currentColor"
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinejoin="round"
				fill="none"
			/>
		</SvgIcon>
	);
}
