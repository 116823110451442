import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
	createScheduledTimeReminderSettings,
	resetScheduledTimeReminderSettings,
	updateScheduledTimeReminderSettings,
} from 'core/api/mySchedule/mySchedule.api';
import {
	ICreateScheduledTimeReminderSettingsParams,
	IScheduledTimeReminderSettings,
	IScheduledTimeReminderSettingsResponse,
	IUpdateScheduledTimeReminderSettingsParams,
} from 'core/api/mySchedule/mySchedule.models';
import { updateProfileInfo } from 'core/api/userAccount/userAccount.api';

export function useMedicationRemindersMutation() {
	const queryClient = useQueryClient();

	const updateTimeReminder = (timeReminder: Partial<IScheduledTimeReminderSettings>) => {
		const oldTimeReminders = queryClient.getQueryData<IScheduledTimeReminderSettingsResponse>([
			'schedule',
			'timeReminders',
		]);

		const newTimeReminders: IScheduledTimeReminderSettingsResponse | undefined = oldTimeReminders
			? {
					...oldTimeReminders,
					'hydra:member':
						oldTimeReminders?.['hydra:member'].map((item) => {
							if (
								item.scheduledTimeHour === timeReminder?.scheduledTimeHour &&
								item.scheduledTimeMinute === timeReminder?.scheduledTimeMinute &&
								item.scheduledTimeMeridiem === timeReminder?.scheduledTimeMeridiem
							) {
								return {
									...item,
									id: timeReminder.id ?? item.id,
									reminderTimeHour: timeReminder.reminderTimeHour ?? null,
									reminderTimeMinute: timeReminder.reminderTimeMinute ?? null,
									reminderTimeMeridiem: timeReminder.reminderTimeMeridiem ?? 'AM',
									enabled: timeReminder.enabled ?? false,
								};
							}

							return item;
						}) || [],
				}
			: undefined;

		queryClient.setQueryData<IScheduledTimeReminderSettingsResponse>(['schedule', 'timeReminders'], newTimeReminders);
	};

	const updateTimeZoneMutation = useMutation({
		mutationFn: (timeZone: string | null) => updateProfileInfo({ timeZone }),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['user'],
			});
		},
	});

	const updateMedicationRemindersStateMutation = useMutation({
		mutationFn: (medicationRemindersEnabled: boolean | null) => updateProfileInfo({ medicationRemindersEnabled }),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['user'],
			});
		},
	});

	const updateTimeReminderMutation = useMutation({
		mutationFn: (reminder: Partial<IScheduledTimeReminderSettings>) => {
			if (reminder?.id && reminder.id !== 0) {
				return updateScheduledTimeReminderSettings(reminder as IUpdateScheduledTimeReminderSettingsParams);
			}

			return createScheduledTimeReminderSettings(reminder as ICreateScheduledTimeReminderSettingsParams);
		},
		onSuccess: updateTimeReminder,
		// On failure, roll back to the previous value
		onError: () => {
			const oldTimeReminders = queryClient.getQueryData<IScheduledTimeReminderSettingsResponse>([
				'schedule',
				'timeReminders',
			]);
			queryClient.setQueryData(['schedule', 'timeReminders'], oldTimeReminders);
		},
	});

	const resetAllTimeRemindersMutation = useMutation({
		mutationFn: resetScheduledTimeReminderSettings,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['schedule', 'timeReminders'],
			});
		},
	});

	return {
		updateTimeZoneMutation,
		updateMedicationRemindersStateMutation,
		updateTimeReminderMutation,
		resetAllTimeRemindersMutation,
	};
}

export default useMedicationRemindersMutation;
