import { Theme } from '@mui/material/styles';
import { Variant } from '@mui/material/styles/createTypography';

export default {
	defaultProps: {
		primaryTypographyProps: {
			variant: 'body1',
		},
		secondaryTypographyProps: {
			variant: 'body1',
		},
	},
	styleOverrides: {
		root: ({ theme, type, variant }: { theme: Theme; type?: string; variant?: Variant }) => ({
			...(type === 'inline' && {
				margin: 0,
			}),

			'& .MuiListItemText-primary': {
				...(variant && {
					...theme.typography[variant],
					fontWeight: 700,
				}),

				...(type === 'inline' && {
					display: 'inline',
					marginRight: '0.5rem',
				}),
			},
			'& .MuiListItemText-secondary': {
				...theme.typography.body1,

				...(variant && {
					...theme.typography[variant],
				}),

				...(type === 'inline' && {
					display: 'inline',
				}),
			},
		}),
		primary: ({ theme }: { theme: Theme }) => ({
			color: theme.palette.text.secondary,
		}),
	},
};
