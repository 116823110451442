import React, { useEffect, useMemo } from 'react';
import { InboxStatus } from 'core/api/inboxNotification';
import { ContentCard } from 'components/card';
import { InboxMessagesList, InboxMessagesTabs } from 'components/inbox';
import { useQueryClient } from '@tanstack/react-query';
import { generatePath, useParams, Link as RouterLink } from 'react-router-dom';
import routes from 'routes';
import { useInboxNotificationsQuery } from 'hooks';
import Suspense from 'components/Suspense';
import { Pagination, PaginationItem, Stack } from '@mui/material';

export default function InboxMessagesPage() {
	const queryClient = useQueryClient();
	const { tab = 'all', page = '1' } = useParams();

	const tabs = useMemo(
		() => [
			{
				label: 'All',
				key: 'all',
				to: generatePath(routes.inbox.list, {
					tab: 'all',
				}),
			},
			{
				label: 'Unread',
				key: 'unread',
				to: generatePath(routes.inbox.list, {
					tab: 'unread',
				}),
			},
			{
				label: 'Deleted',
				key: 'deleted',
				to: generatePath(routes.inbox.list, {
					tab: 'deleted',
				}),
			},
		],
		[]
	);

	const inboxState = useMemo(() => {
		let statusId: InboxStatus | InboxStatus[] = InboxStatus.Inbox;
		let exists: { readAt?: boolean } = { readAt: undefined };

		if (tab === 'all') {
			statusId = InboxStatus.Inbox;
			exists = { readAt: undefined };
		} else if (tab === 'unread') {
			statusId = InboxStatus.Inbox;
			exists = { readAt: false };
		} else if (tab === 'deleted') {
			statusId = InboxStatus.Deleted;
			exists = { readAt: undefined };
		}

		return {
			page: Number(page),
			statusId,
			exists,
		};
	}, [tab, page]);

	const { items: messages, pagination, isLoading } = useInboxNotificationsQuery(inboxState);

	const paginationEl = useMemo(() => {
		if (!pagination?.lastPage) {
			return null;
		}

		return (
			<Pagination
				shape="rounded"
				count={pagination.lastPage}
				page={Number(page)}
				renderItem={(item) => (
					<PaginationItem
						component={RouterLink}
						to={generatePath(routes.inbox.list, {
							tab,
							page: item.page,
						})}
						state={{ showSidebarNavigation: false }}
						{...item}
					/>
				)}
			/>
		);
	}, [pagination, page, tab]);

	useEffect(() => {
		queryClient.invalidateQueries({ queryKey: ['inbox', 'list'] });
	}, [queryClient]);

	return (
		<ContentCard
			title="Inbox Messages"
			sx={{ '& .MuiCardContent-root': { gap: 0, p: 0 } }}
			className="EcaPaper-inboxMessages"
		>
			<InboxMessagesTabs {...{ tabs, value: tab }} />
			<Suspense isLoading={isLoading}>
				<Stack gap={2} sx={{ background: (theme) => theme.palette.background.default, paddingBottom: '24px' }}>
					<Stack>
						<InboxMessagesList messages={messages} />
						{paginationEl}
					</Stack>
				</Stack>
			</Suspense>
		</ContentCard>
	);
}
