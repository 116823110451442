import React from 'react';
import { Dayjs } from 'dayjs';
import { Stack, Typography } from '@mui/material';
import { AsNeededMedByDayList, MedScheduleListByDay } from 'components/common/mySchedule';
import Suspense from 'components/Suspense';
import { MedScheduleListByDayLoading } from 'components/common/mySchedule/MedScheduleListByDay';
import { useAsNeededMedListQuery, useMedScheduleMutation, useScheduledMedsListQuery } from 'hooks/enterprise';
import {
	IDeleteRecordTakenAsNeededMedRequestParams,
	IRecordTakenAsNeededMedRequestParams,
	IRecordTakenRequestParams,
	IScheduledMedDetails,
} from 'core/api/mySchedule/mySchedule.models';
import useDialog from 'hooks/useDialog';
import { MedScheduleDetails } from 'components/patient/mySchedule';
import Dialog from 'components/dialog';

interface SchedulerDayDetailsProps {
	daySelected: Dayjs;
	patientId: string;
}

export function SchedulerDayDetails({ patientId, daySelected }: SchedulerDayDetailsProps) {
	const { recordTakenScheduledMeds, asNeededRecordTaken, deleteAsNeededRecordTaken } = useMedScheduleMutation();
	const { data: scheduledMedsData, isLoading: isLoadingScheduledMedsData } = useScheduledMedsListQuery({
		day: daySelected.format('YYYY-MM-DD'),
		patientId,
	});
	const handleLogChange = (props: IRecordTakenRequestParams) =>
		recordTakenScheduledMeds.mutate({ patientId, ...props });

	const { data: asNeededMedData, isLoading: isLoadingAsNeededMedData } = useAsNeededMedListQuery({
		day: daySelected.format('YYYY-MM-DD'),
		patientId,
	});
	const handleTakeAsNeededMed = (props: IRecordTakenAsNeededMedRequestParams) =>
		asNeededRecordTaken.mutate({ patientId, ...props });
	const handleDeleteTakeAsNeededMed = (props: IDeleteRecordTakenAsNeededMedRequestParams) =>
		deleteAsNeededRecordTaken.mutate({ patientId, ...props });

	const [isOpenScheduleDetailsDialog, openScheduleDetailsDialog, closeScheduleDetailsDialog, scheduleDetails] =
		useDialog<IScheduledMedDetails>();

	return (
		<Stack useFlexGap spacing={2} direction="column">
			<Suspense fallback={<MedScheduleListByDayLoading />} isLoading={isLoadingScheduledMedsData}>
				<MedScheduleListByDay
					data={scheduledMedsData}
					isPending={recordTakenScheduledMeds.isPending}
					onLogChange={handleLogChange}
					onShowScheduleDetails={openScheduleDetailsDialog}
				/>
			</Suspense>

			<Stack>
				<Typography variant="h3" fontWeight="bold" mb={1}>
					As-Needed Medication
				</Typography>
				<Suspense isLoading={isLoadingAsNeededMedData}>
					<AsNeededMedByDayList
						daySelected={daySelected}
						data={asNeededMedData}
						onTakeAsNeededMed={handleTakeAsNeededMed}
						onDeleteTakeAsNeededMed={handleDeleteTakeAsNeededMed}
					/>
				</Suspense>
			</Stack>

			<Dialog
				fullWidth
				maxWidth="md"
				title={`${scheduleDetails?.title} Medication`}
				open={isOpenScheduleDetailsDialog}
				onClose={closeScheduleDetailsDialog}
			>
				{scheduleDetails && (
					<MedScheduleDetails
						{...scheduleDetails}
						onLogChange={handleLogChange}
						onBackButton={closeScheduleDetailsDialog}
					/>
				)}
			</Dialog>
		</Stack>
	);
}

export default SchedulerDayDetails;
