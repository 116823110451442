import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import InfoCardWidget from 'components/InfoCardWidget';
import { isActiveRoute, camelize } from 'utils';
import SidebarListItem from '../sidebarList/SidebarListItem';
import SidebarList from '../sidebarList/SidebarList';

interface MenuItem {
	label: string;
	to: string;
	state?: any;
	icon?: React.ReactNode;
	activeRoutes?: string[];
	disabled?: boolean;
}

interface SidebarMenuProps {
	menuItems: MenuItem[];
}

function SidebarMenu({ menuItems }: SidebarMenuProps) {
	const location = useLocation();

	return (
		<SidebarList>
			{menuItems.map(({ label, to, icon, activeRoutes, state, disabled }) => (
				<SidebarListItem
					key={label}
					component={RouterLink}
					to={disabled ? '#' : to}
					state={state}
					className={`EcaListItem-${camelize(label)}`}
					{...{ disabled, selected: isActiveRoute(location.pathname, activeRoutes) }}
				>
					<InfoCardWidget icon={icon} title={label} />
				</SidebarListItem>
			))}
		</SidebarList>
	);
}

export default SidebarMenu;
