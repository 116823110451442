import React from 'react';
import useDownloadPdf, { ERROR } from 'hooks/common/useDownloadPdf';
import { SidebarListItem } from 'components/sidebarList';
import InfoCardWidget from 'components/InfoCardWidget';
import { PrintIcon } from 'components/icons/PrintIcon';

interface DownloadPdfButtonWidgetProps {
	title: string;
	fetchPdfData: () => Promise<Blob>;
	onError?: (error: ERROR) => void;
	onSuccess?: () => void;
}

export function DownloadPdfButtonWidget({ title, fetchPdfData, onSuccess, onError }: DownloadPdfButtonWidgetProps) {
	const { isLoading, downloadPdfAction } = useDownloadPdf({ fetchPdfData });

	const handleDownloadPdf = () => {
		downloadPdfAction({ onSuccess, onError });
	};

	return (
		<SidebarListItem onClick={handleDownloadPdf}>
			<InfoCardWidget title={isLoading ? 'Loading...' : title} icon={<PrintIcon />} disableArrow />
		</SidebarListItem>
	);
}

export default DownloadPdfButtonWidget;
